import { useState, useEffect } from "react";
import { Grid, Card } from "components/lynx-components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddLookupHeader } from "./../add-lookup-header";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import { EditLookupModal } from "./../edit-lookup-modal";
import Button from "@mui/material/Button";
import {
  getCustomFields,
  updateCustomFieldSort,
} from "../../../../services/custom-fields-service";
import { CustomFieldModal } from "../EventSettings/custom-field-modal";
import { permitFormFieldColumns } from "./permit-form-fields-columns";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { EntityTypes, LookupTypes } from "../../../../types/enums";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

export function PermitSettings() {
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };

  const lookupColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleLookupEdit(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const [permitTypes, setPermitTypes] = useState([]);
  const [permitCustomFields, setPermitCustomFields] = useState([]);
  const [selectedLookup, setSelectedLookup] = useState({});
  const [selectedCustomField, setSelectedCustomField] = useState({});
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [getLookupsTrigger] = useLazyGetLookupsQuery();

  const history = useHistory();
  useEffect(() => {
    loadPermitTypes();
    loadCustomFields();
  }, []);

  const loadPermitTypes = () => {
    getLookupsTrigger({lookupType: LookupTypes.PermitType}).then((res) => {
      setPermitTypes(res.data);
    });
  };

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.Permit).then((res) => {
      setPermitCustomFields(res.data);
    });
  };

  const handleCustomFieldRowOrderChange = (e) => {
    updateCustomFieldSort({
      entityId: e.row.id,
      newPosition: e.targetIndex + 1,
      entityType: EntityTypes.Permit,
    });
    let newValues = [...permitCustomFields];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setPermitCustomFields(newValues);
  };

  const handleCustomFieldModalClosed = (refresh) => {
    setShowCustomFieldModal(false);

    loadCustomFields();
    setSelectedCustomField({});
  };

  const handleCustomFieldEdit = (e) => {
    if (e.field == "edit") {
      setSelectedCustomField(permitCustomFields.find((x) => x.id == e.row.id));
      setShowCustomFieldModal(true);
    }
  };

  const handleLookupEdit = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleLookupModalSave = () => {
    handleLookupModalClose();
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Permits
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Permits</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Permit Types
            </Typography>
            {/* <Typography variant="body2" className="mt-2" component="div">
              COME BACK TO
            </Typography> */}
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType={LookupTypes.PermitType}
              lookupName={LookupTypes.PermitType}
              handleAddLookup={() => loadPermitTypes()}
            ></AddLookupHeader>
            <div style={{ height: 330, width: "100%" }}>
              <DataGridPro
                rows={permitTypes}
                columns={lookupColumns}
                disableMultipleRowSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Permit Form Fields
            </Typography>
            {/* <Typography variant="body2" className="mt-2" component="div">
              COME BACK TO
            </Typography> */}
          </div>
        </Grid.Col>

        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => setShowCustomFieldModal(true)}
              >
                Add
              </Button>
            </Card.Header>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                columns={permitFormFieldColumns}
                rows={permitCustomFields}
                onRowOrderChange={handleCustomFieldRowOrderChange}
                onCellClick={handleCustomFieldEdit}
                rowReordering
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                disableChildrenSorting
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>{" "}
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showCustomFieldModal}
        onClose={() => {
          setShowCustomFieldModal(false);
          setSelectedCustomField({});
        }}
      >
        <CustomFieldModal
          handleModalClose={handleCustomFieldModalClosed}
          customField={selectedCustomField}
          entityType={EntityTypes.Permit}
        ></CustomFieldModal>
      </Dialog>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupModalSave}
          lookupName={selectedLookup.lookupType}
        />
      </Dialog>
    </Grid>
  );
}
