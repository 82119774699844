import { Box } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import { Card, Grid, Dimmer, Form } from "components/lynx-components";
import _ from "lodash";
import { useAppSelector } from "modules/hooks";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { canSubmitStageAction } from "services/incident-assignment-service";
import { navigateTo } from "services/navigation-service";
import { ActionDto, EventDto, IncidentDto, LookupDto, UserDto } from "types";
import { getUserId, roleMatch } from "../../../actions/auth";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import { getEvent } from "../../../services/events";
import { validationService } from "../../../services/validation";
import {
  ActionStatuses,
  ActionUserTypes,
  LookupTypes,
  UserRoles,
} from "../../../types/enums";
import { AttachmentViewer } from "../../attachment-viewer";
import { LynxDialog } from "../../lynx-dialog";
import PageNotFound from "../../PageNotFound";
import "./action-form.css";
import useAlert from "hooks/useAlert";
import {
  useCreateActionMutation,
  useLazyGetActionQuery,
  usePatchActionMutation,
} from "services/rtkApi/endpoints/actions";
import { useLazyGetIncidentQuery } from "services/rtkApi/endpoints/incidents";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
interface RouteParams {
  id: string;
  eventId: string;
  incidentId: string;
}
const ACTION_TITLE_MAX_LENGTH = 50;

export function ActionForm() {
  const history = useHistory();

  const [getLookupsTrigger] = useLazyGetLookupsQuery();

  const account = useAppSelector((state) => state.account);
  const users = useAppSelector((state) => state.lookups.users);
  const params = useParams<RouteParams>();
  const [getActionTrigger] = useLazyGetActionQuery();

  interface FormState extends ActionDto {
    descriptionError?: string;
    titleError?: string;
    priorityError?: string;
    categoryIdError?: string;
    assignedToUserIdError?: string;
    dueDateError?: string;
    evidenceRequiredIdError?: string;
    completedCommentError?: string;
    verifiedCommentError?: string;
  }
  const initalFrom: FormState = {
    title: "",
    description: "",
    priority: "",
    completedComment: "",
    dueDate: "",
    verifiedComment: "",
    approvedDateTime: "",
    descriptionError: "",
    titleError: "",
    priorityError: "",
    categoryIdError: "",
    assignedToUserIdError: "",
    dueDateError: "",
    evidenceRequiredIdError: "",
    completedCommentError: "",
    verifiedCommentError: "",
  };
  const [availableUsers, setAvailableUsers] = useState<UserDto[]>([]);
  const [formState, setFormState] = useState(initalFrom);
  const [categories, setCategories] = useState([] as LookupDto[]);
  const [evidenceRequired, setEvidenceRequired] = useState([] as LookupDto[]);
  const [existingAction, setExistingAction] = useState({} as ActionDto);
  const [event, setEvent] = useState({} as EventDto);
  const [incident, setIncident] = useState({} as IncidentDto);
  const [uploadedFiles, setUploadedFiles] = useState([] as File[]);
  const [actionSaving, setActionSaving] = useState(false);
  const [actionLoading, setActionLoading] = useState(true);
  const [entityLoading, setEntityLoading] = useState(true);
  const [lookupsLoading, setLookupsLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);

  const { showAlert } = useAlert();

  const [createActionTrigger] = useCreateActionMutation();
  const [patchActionTrigger] = usePatchActionMutation();
  const [getIncidentTrigger] = useLazyGetIncidentQuery();

  useEffect(() => {
    if (params.id) {
      loadAction();
    } else {
      setActionLoading(false);
    }
    loadLookups();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(users)) {
      if (params.eventId || formState.linkedEventId) {
        setAvailableUsers(users?.filter((x) => x.isEventsUser) ?? []);
      }
      if (params.incidentId || formState.linkedIncidentId) {
        setAvailableUsers(users?.filter((x) => x.isIncidentsUser) ?? []);
      }
    }
  }, [users, formState.linkedEventId, formState.linkedIncidentId]);
  useEffect(() => {
    if (params.eventId) {
      loadEvent(_.toNumber(params.eventId));
    }
    if (params.incidentId) {
      loadIncident(_.toNumber(params.incidentId));
    }
  }, []);
  const canApproveAction = () => {
    if (roleMatch([UserRoles.Admin])) {
      return true;
    }
    if (!_.isEmpty(event)) {
      if (roleMatch([UserRoles.EventsAdministrator])) {
        return true;
      }
      return false;
    }
    if (_.isEmpty(event) && _.isEmpty(incident)) {
      return false;
    }

    if (!_.isEmpty(incident)) {
      return canSubmitStageAction(
        existingAction.actionUsers,
        account.id,
        ActionUserTypes.Approver
      );
    }
  };

  const canVerifyAction = () => {
    if (roleMatch([UserRoles.Admin])) {
      return true;
    }
    if (!_.isEmpty(event)) {
      if (roleMatch([UserRoles.EventsAdministrator])) {
        return true;
      }
      return false;
    }
    if (_.isEmpty(event) && _.isEmpty(incident)) {
      return false;
    }
    if (!_.isEmpty(incident)) {
      return canSubmitStageAction(
        existingAction.actionUsers,
        account.id,
        ActionUserTypes.Verifier
      );
    }
    return false;
  };

  const canCompleteAction = () => {
    return (
      existingAction.assignedToUserId == getUserId() ||
      canApproveAction() ||
      canVerifyAction() ||
      roleMatch([UserRoles.Admin])
    );
  };

  const isCommentFieldDisabled = () => {
    if (isApprovedStatus()) {
      return false;
    }
    if (
      _.toLower(existingAction.status) ==
        _.toLower(ActionStatuses.PendingVerification) &&
      canCompleteAction()
    ) {
      return false;
    }
    return true;
  };

  const isDetailsFieldLocked = () => {
    if (isExistingAction()) {
      if (
        _.toLower(existingAction.status) == _.toLower(ActionStatuses.Closed)
      ) {
        return true;
      }
      if (
        isApprovedStatus() ||
        _.toLower(existingAction.status) ==
          _.toLower(ActionStatuses.PendingVerification)
      ) {
        if (!canApproveAction()) {
          return true;
        }
      }
    }

    return false;
  };

  const loadAction = () => {
    getActionTrigger(+params.id)
      .then((res) => {
        setFormStateFromAction(res.data);
        setExistingAction(res.data);
      })
      .catch((err) => {
        setShowNotFound(true);
      });
  };

  const loadEvent = (eventId: number) => {
    getEvent(eventId).then((res) => {
      setEvent(res.data);
      setEntityLoading(false);
      if (!isExistingAction()) {
        setFormState({
          ...formState,
          linkedEventId: res.data.id,
        });
      }
    });
  };

  const loadIncident = (incidentId: number) => {
    getIncidentTrigger(incidentId).then((res) => {
      setIncident(res.data);
      setEntityLoading(false);
      if (!isExistingAction()) {
        setFormState({
          ...formState,
          linkedIncidentId: res.data.id,
        });
      }
    });
  };

  const setFormStateFromAction = (action: ActionDto) => {
    let newState = {
      title: action.title,
      description: action.description,
      priority: action.priority,
      assignedToUserId: action.assignedToUserId,
      evidenceRequiredId: action.evidenceRequiredId,
      dueDate: dateUtil.convertDateOnlyToLocal(action.dueDate),
      approvedByUserId: action.approvedByUserId,
      approvedDateTime: action.approvedDateTime,
      categoryId: action.categoryId,
      completedComment: action.completedComment,
      verifiedComment: action.verifiedComment,
      linkedEventId: action.linkedEventId,
      linkedIncidentId: action.linkedIncidentId,
    };
    if (action.linkedEventId) {
      loadEvent(action.linkedEventId);
    }
    if (action.linkedIncidentId) {
      loadIncident(action.linkedIncidentId);
    }

    setFormState(newState);
    setActionLoading(false);
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const loadLookups = () => {
    loadCategories();
    loadEvidenceRequired();
    setLookupsLoading(false);
  };

  const loadCategories = () => {
    getLookupsTrigger({ lookupType: LookupTypes.ActionCategory }, true).then(
      (res) => {
        setCategories(res.data);
      }
    );
  };

  const loadEvidenceRequired = () => {
    getLookupsTrigger(
      { lookupType: LookupTypes.ActionEvidenceRequired },
      true
    ).then((res) => {
      setEvidenceRequired(res.data);
    });
  };

  const isExistingAction = () => {
    return params.id ? true : false;
  };

  const isForEvent = () => {
    return params.eventId;
  };

  const isForIncident = () => {
    return params.incidentId;
  };

  const isApprovedStatus = () => {
    return (
      _.toLower(existingAction.status) == _.toLower(ActionStatuses.Approved) ||
      _.toLower(existingAction.status) == _.toLower(ActionStatuses.Overdue)
    );
  };

  const saveForm = (saveAction?: string) => {
    if (!validateDataForSave(saveAction)) {
      return;
    }
    let form = validationService.unsetErrors(
      formState,
      "descriptionError",
      "titleError",
      "priorityError",
      "categoryIdError",
      "assignedToUserIdError",
      "dueDateError",
      "evidenceRequiredIdError",
      "completedCommentError",
      "verifiedCommentError"
    );

    let formToSave = _.cloneDeep(form);
    setActionSaving(true);
    if (!isExistingAction()) {
      createActionTrigger({ dto: formToSave, files: uploadedFiles })
        .then((res) => {
          showAlert("success", "Action created.");
          setExistingAction(res.data);
          setFormStateFromAction(res.data);
          if (isForEvent()) {
            history.push(`/events/${params.eventId}/actions/${res.data.id}`);
          } else if (isForIncident()) {
            history.push(
              `/incidents/${params.incidentId}/actions/${res.data.id}`
            );
          } else {
            history.push(`/actions/${res.data.id}`);
          }
        })
        .finally(() => setActionSaving(false));
    } else {
      patchActionTrigger({
        id: +params.id,
        jPatch: formToSave,
        saveAction: saveAction,
      })
        .then((res) => {
          if (res.data) {
            showAlert(
              "success",
              saveAction == "closenotapproved"
                ? "Action closed."
                : saveAction == "approve"
                ? "Action approved."
                : saveAction == "closeverified"
                ? "Action verified."
                : saveAction == "complete"
                ? "Action completed."
                : "Action saved."
            );
            setExistingAction(res.data);
            setFormStateFromAction(res.data);
          }
        })
        .finally(() => setActionSaving(false));
    }
  };

  const validateDataForSave = (saveAction?: string) => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "description",
      "descriptionError",
      "Description"
    );

    validationService.validateRequiredField(
      newState,
      "title",
      "titleError",
      "Action"
    );

    validationService.validateRequiredField(
      newState,
      "priority",
      "priorityError",
      "Priority"
    );

    validationService.validateRequiredField(
      newState,
      "categoryId",
      "categoryIdError",
      "Category"
    );

    validationService.validateRequiredField(
      newState,
      "assignedToUserId",
      "assignedToUserIdError",
      "Assigned To"
    );
    validationService.validateRequiredField(
      newState,
      "dueDate",
      "dueDateError",
      "Due Date"
    );
    validationService.validateRequiredField(
      newState,
      "evidenceRequiredId",
      "evidenceRequiredIdError",
      "Evidence Required"
    );
    let errorFields = [
      "descriptionError",
      "titleError",
      "priorityError",
      "categoryIdError",
      "assignedToUserIdError",
      "dueDateError",
      "evidenceRequiredIdError",
    ];
    if (_.toLower(saveAction) == "complete") {
      validationService.validateRequiredField(
        newState,
        "completedComment",
        "completedCommentError",
        "Completed Comment"
      );
      errorFields = [...errorFields, "completedCommentError"];
    }
    if (_.toLower(saveAction) == "closeverified") {
      validationService.validateRequiredField(
        newState,
        "verifiedComment",
        "verifiedCommentError",
        "Verified Comment"
      );
      errorFields = [...errorFields, "verifiedCommentError"];
    }

    isFormValid = !validationService.hasError(newState, ...errorFields);

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const hideFooter = () => {
    if (_.toLower(existingAction.status) == _.toLower(ActionStatuses.Closed))
      return true;
    if (
      _.toLower(existingAction.status) ==
        _.toLower(ActionStatuses.PendingVerification) &&
      !canCompleteAction() &&
      !canVerifyAction()
    )
      return true;
    if (isApprovedStatus() && !canCompleteAction()) return true;
    return false;
  };

  const disableSaveFunctions = () => {
    if (!_.isEmpty(event) && roleMatch([UserRoles.EventsViewer])) {
      return true;
    }
    return false;
  };

  if (showNotFound) {
    return <PageNotFound message="Action not found" />;
  } else
    return (
      <Grid>
        <Dimmer
          active={lookupsLoading || entityLoading || actionLoading}
          loader
        >
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                <div className="d-flex">
                  <Typography variant="h3" component="div">
                    {isExistingAction()
                      ? existingAction.actionNumber
                      : "Add Action"}
                  </Typography>
                  {isExistingAction() && (
                    <div className="align-self-center ml-5">
                      {getStatusChip(existingAction)}
                    </div>
                  )}
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12} className="">
                {!isForEvent() && !isForIncident() ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => navigateTo(history, "/actions", e)}
                    >
                      Actions
                    </Link>
                    <Typography color="text.primary">
                      {isExistingAction()
                        ? existingAction.actionNumber
                        : "Add Action"}
                    </Typography>
                  </Breadcrumbs>
                ) : isForEvent() ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => navigateTo(history, "/events", e)}
                    >
                      Events
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) =>
                        navigateTo(history, `/events/${event.id}`, e)
                      }
                    >
                      {event.eventNumber}
                    </Link>
                    <Typography color="text.primary">
                      {isExistingAction()
                        ? existingAction.actionNumber
                        : "Add Action"}
                    </Typography>
                  </Breadcrumbs>
                ) : (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => navigateTo(history, "/incidents", e)}
                    >
                      Incidents
                    </Link>

                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) =>
                        navigateTo(
                          history,
                          `/incidents/${incident.id}?view=actions`,
                          e
                        )
                      }
                    >
                      {incident.incidentNumber}
                    </Link>
                    <Typography color="text.primary">
                      {isExistingAction()
                        ? existingAction.actionNumber
                        : "Add Action"}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Grid.Col>
            </Grid.Row>
          </Paper>

          <Grid.Row>
            <Container className="form-container" maxWidth="xl">
              <Form className="card">
                <Card.Header>
                  <Card.Title>Details</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    {(isForEvent() || existingAction.linkedEventId) && (
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Source Event">
                          <Form.Select
                            name="linkedEventId"
                            disabled
                            onChange={handleInputChange}
                            value={formState.linkedEventId}
                          >
                            <option value={event.id}>
                              {event.eventNumber}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                    )}
                    {(isForIncident() || existingAction.linkedIncidentId) && (
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Source Incident">
                          <Form.Select
                            name="linkedIncidentId"
                            disabled
                            onChange={handleInputChange}
                            value={formState.linkedIncidentId}
                          >
                            <option value={event.id}>
                              {incident.incidentNumber}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                    )}
                    <Grid.Col md={12} width={12}>
                      <Form.Group label="Action" isRequired>
                        <Form.Input
                          maxLength={ACTION_TITLE_MAX_LENGTH}
                          type="text"
                          name="title"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          value={formState.title}
                          error={formState.titleError}
                        ></Form.Input>
                        <Box
                          fontSize={13}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            mr: 2,
                          }}
                        >{`${formState.title?.length}/${ACTION_TITLE_MAX_LENGTH}`}</Box>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={12} width={12}>
                      <Form.Group label="Description" isRequired>
                        <LynxTextArea
                          name="description"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          value={formState.description}
                          error={formState.descriptionError}
                        ></LynxTextArea>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group isRequired label="Priority">
                        <Form.Select
                          name="priority"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          value={formState.priority}
                          error={formState.priorityError}
                        >
                          <option value={""}></option>
                          <option value="P1">P1</option>
                          <option value="P2">P2</option>
                          <option value="P3">P3</option>
                          <option value="P4">P4</option>
                          <option value="P5">P5</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group isRequired label="Category">
                        <Form.Select
                          name="categoryId"
                          onChange={handleInputChange}
                          value={formState.categoryId}
                          disabled={isDetailsFieldLocked()}
                          error={formState.categoryIdError}
                        >
                          <option value={""}></option>
                          {categories.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.code}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group label="Assigned To" isRequired>
                        <Form.Select
                          name="assignedToUserId"
                          onChange={handleInputChange}
                          value={formState.assignedToUserId}
                          disabled={isDetailsFieldLocked()}
                          error={formState.assignedToUserIdError}
                        >
                          <option value={""}></option>
                          {_.sortBy(availableUsers, ["fullName"]).map(
                            (user) => (
                              <option value={user.id} key={user.id}>
                                {`${user.fullName}`}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group label="Due Date" isRequired>
                        <Form.Input
                          type="date"
                          value={formState.dueDate}
                          name="dueDate"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          error={formState.dueDateError}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group isRequired label="Evidence Required">
                        <Form.Select
                          name="evidenceRequiredId"
                          onChange={handleInputChange}
                          value={formState.evidenceRequiredId}
                          error={formState.evidenceRequiredIdError}
                          disabled={isDetailsFieldLocked()}
                        >
                          <option value={""}></option>
                          {evidenceRequired.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.code}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    {((isApprovedStatus() && canCompleteAction()) ||
                      _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.PendingVerification) ||
                      _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.Closed)) && (
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Completed Comment" isRequired>
                          <LynxTextArea
                            disabled={isCommentFieldDisabled()}
                            name="completedComment"
                            onChange={handleInputChange}
                            value={formState.completedComment}
                            error={formState.completedCommentError}
                          ></LynxTextArea>
                        </Form.Group>
                      </Grid.Col>
                    )}
                    {(_.toLower(existingAction.status) ==
                      _.toLower(ActionStatuses.PendingVerification) ||
                      _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.Closed)) && (
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Verified Comment" isRequired>
                          <LynxTextArea
                            name="verifiedComment"
                            disabled={
                              !canVerifyAction() ||
                              _.toLower(existingAction.status) == "closed"
                            }
                            onChange={handleInputChange}
                            value={formState.verifiedComment}
                            error={formState.verifiedCommentError}
                          ></LynxTextArea>
                        </Form.Group>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                </Card.Body>
                {!hideFooter() && !disableSaveFunctions() && (
                  <Card.Footer>
                    {!isExistingAction() && (
                      <Button
                        variant="contained"
                        className="float-right"
                        onClick={() => saveForm()}
                      >
                        Submit
                      </Button>
                    )}
                    {_.toLower(existingAction.status) == "pending approval" && (
                      <>
                        <Button
                          variant="contained"
                          className="float-right ml-2 action-button"
                          onClick={() => saveForm()}
                        >
                          Save Changes
                        </Button>
                        {canApproveAction() && (
                          <>
                            <Button
                              variant="contained"
                              color="error"
                              className="float-right ml-2 action-button"
                              onClick={() => saveForm("closenotapproved")}
                            >
                              Close - Not Approved
                            </Button>
                            <Button
                              variant="contained"
                              color="success"
                              className="float-right action-button"
                              onClick={() => saveForm("approve")}
                            >
                              Approve
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {isApprovedStatus() && (
                      <>
                        {canCompleteAction() && (
                          <Button
                            variant="contained"
                            className="float-right"
                            onClick={() => saveForm()}
                          >
                            Save Changes
                          </Button>
                        )}
                        {canCompleteAction() && (
                          <Button
                            variant="contained"
                            color="success"
                            className="float-right mr-2"
                            onClick={() => saveForm("complete")}
                          >
                            Complete
                          </Button>
                        )}
                      </>
                    )}
                    {_.toLower(existingAction.status) ==
                      _.toLower(ActionStatuses.PendingVerification) && (
                      <>
                        <Button
                          variant="contained"
                          className="float-right"
                          onClick={() => saveForm()}
                        >
                          Save Changes
                        </Button>
                        {canVerifyAction() && (
                          <Button
                            variant="contained"
                            color="success"
                            className="float-right mr-2"
                            onClick={() => saveForm("closeverified")}
                          >
                            Close - Verified
                          </Button>
                        )}
                      </>
                    )}
                  </Card.Footer>
                )}
              </Form>
              <Grid.Row>
                <Grid.Col
                  md={isExistingAction() ? 6 : 12}
                  width={12}
                  className="pl-0 pr-0"
                >
                  <div className="action-details-card">
                    <AttachmentViewer
                      isExistingEntity={isExistingAction()}
                      entityType={"Action"}
                      disableDelete={
                        _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.Closed)
                      }
                      entityId={existingAction.id}
                      handleSetUploadedFiles={(files: File[]) => {
                        setUploadedFiles(files);
                      }}
                      rowHeight={300}
                    />
                  </div>
                </Grid.Col>
                {isExistingAction() && (
                  <Grid.Col md={6} width={12} className="pl-0 pr-0">
                    {getHistoryCard(existingAction)}
                  </Grid.Col>
                )}
              </Grid.Row>
              <div style={{ width: "50%" }}></div>{" "}
            </Container>
          </Grid.Row>
          {actionSaving && (
            <LynxDialog
              open={actionSaving}
              title="Saving action. Do not close the window."
              description={
                <>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <CircularProgress />
                  </div>
                </>
              }
            />
          )}
        </Dimmer>
      </Grid>
    );
}
export const getStatusChip = (
  action: ActionDto,
  size: "small" | "medium" = "small"
) => {
  if (_.toLower(action.status) == _.toLower(ActionStatuses.Approved)) {
    return <Chip label={ActionStatuses.Approved} color="success" size={size} />;
  }
  if (_.toLower(action.status) == _.toLower(ActionStatuses.PendingApproval)) {
    return (
      <Chip
        label={ActionStatuses.PendingApproval}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
        size={size}
      />
    );
  }
  if (_.toLower(action.status) == _.toLower(ActionStatuses.Overdue)) {
    return (
      <Chip
        label={ActionStatuses.Overdue}
        sx={{ backgroundColor: lynxColors.error, color: "white" }}
        size={size}
      />
    );
  }
  if (
    _.toLower(action.status) == _.toLower(ActionStatuses.PendingVerification)
  ) {
    return (
      <Chip
        label={ActionStatuses.PendingVerification}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
        size={size}
      />
    );
  }
  return <Chip label={ActionStatuses.Closed} color="primary" size={size} />;
};

function getHistoryCard(existingAction: ActionDto) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>History</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <TableContainer>
          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="right" width="33%">
                  Created By:
                </TableCell>
                <TableCell>{existingAction.createdByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.createdDateTimeUtc
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Approved By:</TableCell>
                <TableCell>{existingAction.approvedByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.approvedDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Completed By:</TableCell>
                <TableCell>{existingAction.completedByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.completedDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Verified By:</TableCell>
                <TableCell>{existingAction.verifierUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.verifiedDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Closed By:</TableCell>
                <TableCell>{existingAction.closedByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.closedDateTime
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
  );
}
