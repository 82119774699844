import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { incidentColumns } from "./incident-columns";
import { LynxTour } from "./../../lynx-tour";
import { incidentGridTourSteps } from "../../../tours/incident-grid-tour";
import { TourTypes } from "../../../types/enums";
import { useSelector } from "react-redux";

import IncidentMobileCard from "./incident-mobile-card";
import { RootState, UserDto } from "types";
import { useHistory } from "react-router-dom";
import {
  useGetIncidentExportAsXLSXMutation,
  useGetIncidentsQuery,
} from "services/rtkApi/endpoints/incidents";
import { EntityTypes } from "types/enums-ts";
import _ from "lodash";

interface PredefinedLookups {
  users: Array<UserDto>;
}

const IncidentsList: React.FC = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const history = useHistory();
  const [getIncidentExportAsXLSXTrigger] = useGetIncidentExportAsXLSXMutation();
  const handleIncidentNumberClick = (row: any) => {
    history.push(`/incidents/${row.id}?view=incident`);
  };

  const navigateToAddIncident = () => {
    history.push(`/submit-incident`);
  };

  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups: PredefinedLookups = { users: users || [] };

  useEffect(() => {
    const newColumns = [...incidentColumns(predefinedLookups)];
    const colIndex = newColumns.findIndex((x) => x.field === "incidentNumber");
    if (colIndex !== -1) {
      newColumns[colIndex].renderCell = (params: any) => {
        return (
          <Button
            variant="text"
            className="incident-number-button"
            onClick={() => handleIncidentNumberClick(params.row)}
          >
            {params.value}
          </Button>
        );
      };
    }
    setColumns(newColumns);
  }, []);

  const getTour = () => {
    return (
      <LynxTour
        title={"Welcome to the Incidents Grid!"}
        description={
          "Incidents are used for submitting and investigating health and safety, environmental, and community incidents. The Incident grid shows your Incidents, and provides a number of options to sort, filter, and export."
        }
        steps={incidentGridTourSteps}
        flagField={"incidentsGridTourCompletedDateTimeUtc"}
        tourType={TourTypes.IncidentsGrid}
      />
    );
  };

  return (
    <>
      {!_.isEmpty(columns) && (
        <LynxDataGrid
          tour={getTour}
          disableRefetchOnMountOrArgChange
          enableSavedFilters
          columns={columns}
          useQuery={useGetIncidentsQuery}
          localStorageName="incidents"
          entityType={EntityTypes.Incident}
          addButtonAction={navigateToAddIncident}
          mobileCard={IncidentMobileCard}
          handleExportXLSX={getIncidentExportAsXLSXTrigger}
          enableReports
        />
      )}
    </>
  );
};

export default IncidentsList;
