import { BaseParams } from "types";
import queryString from "query-string";
import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const BASE_ROUTE = "/monitoringEvents";

const monitoringEventsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getMonitoringEvents: builder.query({
      query: (params: BaseParams) =>
        `${BASE_ROUTE}?${queryString.stringify(params)}`,
      providesTags: [RtkTagTypes.GetMonitoringEvents],
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
    }),
    getMonitoringEvent: builder.query({
      query: (id: number) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetMonitoringEvents, id },
      ],
    }),
    addMonitoringEvent: builder.mutation({
      query: (args: any) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [
        RtkTagTypes.GetMonitoringEvents,
        RtkTagTypes.GetMonitoringResults,
      ],
    }),
    updateMonitoringEvent: builder.mutation({
      query: (args: any) => ({
        url: `${BASE_ROUTE}/${args.id}`,
        method: "POST",
        body: args.body,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetMonitoringEvents, id },
        RtkTagTypes.GetMonitoringEvents,
        RtkTagTypes.GetMonitoringResults,
      ],
    }),
    deleteMonitoringEvent: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        { type: RtkTagTypes.GetMonitoringEvents, id },
        RtkTagTypes.GetMonitoringEvents,
        RtkTagTypes.GetMonitoringResults,
      ],
    }),
    bulkUpdateMonitoringEvents: builder.mutation({
      query: ({ params, data }: { params: any; data: any }) => ({
        url: `${BASE_ROUTE}/bulkUpdate`,
        method: "POST",
        body: data,
        params,
      }),
      invalidatesTags: [RtkTagTypes.GetMonitoringEvents], // Adjust the tag name as needed
    }),
    bulkUpdateMonitoringEventAttachments: builder.mutation({
      query: ({ params, attachments }: { params: any; attachments: any }) => {
        const formData = new FormData();
        attachments.forEach((file: any) => formData.append("file", file));
        return {
          url: `${BASE_ROUTE}/bulkUpdateAttachments`,
          method: "POST",
          body: formData,
          params,
        };
      },
      invalidatesTags: [RtkTagTypes.GetMonitoringEvents], // Adjust the tag name as needed
    }),
  }),
});

export const {
  useLazyGetMonitoringEventQuery,
  useGetMonitoringEventsQuery,
  useAddMonitoringEventMutation,
  useUpdateMonitoringEventMutation,
  useDeleteMonitoringEventMutation,
  useBulkUpdateMonitoringEventsMutation,
  useBulkUpdateMonitoringEventAttachmentsMutation,
} = monitoringEventsEndpoint;
