import { RtkTagTypes } from "types/enums";
import { apiService } from "../apiService";

const BASE_ROUTE = "/lookups";
const CACHE_TIME = 300; //300 seconds = 5 mins
const lookupsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getLookups: builder.query({
      query: (args) => {
        return {
          url: `${BASE_ROUTE}`,
          params: {
            lookupType: args.lookupType,
            bypassFilter: args.bypassFilter || false,
          },
        };
      },
      providesTags: [RtkTagTypes.GetLookups],
      keepUnusedDataFor: CACHE_TIME,
    }),
    getLookup: builder.query({
      query: (id) => {
        return {
          url: `${BASE_ROUTE}/${id}`,
        };
      },
      keepUnusedDataFor: CACHE_TIME,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetLookups, id },
      ],
    }),
    getIncidentLookup: builder.query({
      query: () => {
        return {
          url: `${BASE_ROUTE}/incident`,
        };
      },
      providesTags: [RtkTagTypes.GetLookups],
      keepUnusedDataFor: CACHE_TIME,
    }),
    createLookup: builder.mutation({
      query: (args) => ({
        url: BASE_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetLookups],
    }),
    patchLookup: builder.mutation({
      query: ({ id, dto }) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "PATCH",
        body: dto,
      }),
      invalidatesTags: (result: any, error: any, { id }: any) => [
        RtkTagTypes.GetLookups,
        { type: RtkTagTypes.GetLookups, id },
      ],
    }),
    deleteLookup: builder.mutation({
      query: (id) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetLookups],
    }),
  }),
});

export const {
  useGetLookupsQuery,
  useLazyGetLookupsQuery,
  useLazyGetLookupQuery,
  useLazyGetIncidentLookupQuery,
  useCreateLookupMutation,
  usePatchLookupMutation,
  useDeleteLookupMutation,
} = lookupsEndpoint;
