import _ from "lodash";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layer, Map, NavigationControl, Source } from "react-map-gl";
import { getAssetLayers } from "../../../services/assets";
import { localStorageService } from "../../../services/local-storage-service";
import { getEventsForMap } from "./../../../services/events";
import { getMonitoringEvents } from "./../../../services/monitoring-events";
import { AssetLayer } from "./asset-layer";
import { EventsLayer } from "./events-layer";
import { GeocoderControl } from "./geocoder-control";
import { MapLegend } from "./map-legend";
import { MapSettingsSidebar } from "./map-settings-sidebar";
import { MapSidebar } from "./map-sidebar";
import { MapTopOverlay } from "./map-top-overlay";
import { MonitoringLayer } from "./monitoring-layer";
import { Form } from "components/lynx-components";
import FeatureService from "mapbox-gl-arcgis-featureserver";
import {
  hasAnyEventRole,
  hasAnyMonitoringRole
} from "../../../actions/auth";
import { getMapLayers } from "../../../services/map";
import { getMonitoringLocations } from "../../../services/monitoring-location-service";
import {
  EntityTypes,
  LocalStorageKeys,
  LookupTypes,
  MapLayerKeys,
  MapLayerNames,
  MapSourceTypes
} from "../../../types/enums";
import { MonitoringLocationsLayer } from "./monitoring-locations-layer";
import { LynxDialog } from "../../lynx-dialog";
import { MultiSelect } from "../../form-controls/multi-select";
import useAlert from "hooks/useAlert";
import { useHistory } from "react-router-dom";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoidGhlY2FydGVybWF0dCIsImEiOiJjandwMHlvenoxeG1oNGJwNnFiajE5cTFoIn0.IdIaSdXJWz7LPfcsVa9_rA";
export const baseMapStyleKey = "baseMapStyle";
export function DashboardMap(props) {
  const targetRef = useRef();
  const mapRef = useRef(null);
  const [size, setSize] = useState([0, 0]);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [assetLayers, setAssetLayers] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventLegendKey, setEventLegendKey] = useState("Status");
  const [selectedData, setSelectedData] = useState([]);
  const [monitoringEvents, setMonitoringEvents] = useState([]);
  const [action, setAction] = useState("");
  const [enabledLayers, setEnabledLayers] = useState([]);

  const [viewState, setViewState] = React.useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5,
  });
  const [sidebarType, setSidebarType] = useState("");
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState({});
  const [baseMapStyle, setBaseMapStyle] = useState(null);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [filteredMonitoringLocations, setFilteredMonitoringLocations] =
    useState([]);
  const [monitoringLocationTypes, setMonitoringLocationTypes] = useState([]);
  const [dialogTitle, setDialogTitle] = useState(null);
  const navControlStyle = {
    left: 10,
    top: 10,
  };
  const sidebarWidth = 250;
  const [layers, setLayers] = useState([]);
  const { showAlert } = useAlert();

  const history = useHistory();
  const [getLookupsTrigger] = useLazyGetLookupsQuery();

  useEffect(() => {
    prepareMainDashboard();
    if (hasAnyEventRole()) {
      loadEvents();
    }
    var baseStyle = localStorageService.getLocalStorage(baseMapStyleKey);
    if (baseStyle) {
      setBaseMapStyle(baseStyle);
    } else {
      setBaseMapStyle(mapStyles[0]);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(layers) && mapLoaded) {
      toggleVisibleLayers();
    }
  }, [layers, enabledLayers, mapLoaded]);

  const toggleVisibleLayers = (e) => {
    let idsToRemove = layers
      .map((x) => x.key)
      .filter((x) => !enabledLayers.includes(x));
    let idsToAdd = _.cloneDeep(enabledLayers);

    idsToRemove.forEach((id) => {
      if (id == MapLayerKeys.Assets) {
        return;
      }
      const visibility = mapRef.current
        .getMap()
        .getLayoutProperty(id, "visibility");
      if (visibility === "visible") {
        mapRef.current.getMap().setLayoutProperty(id, "visibility", "none");
      }
    });

    idsToAdd.forEach((id) => {
      if (id == MapLayerKeys.Assets) {
        return;
      }
      const visibility = mapRef.current
        .getMap()
        .getLayoutProperty(id, "visibility");
      if (visibility === "none") {
        mapRef.current.getMap().setLayoutProperty(id, "visibility", "visible");
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(layers) && mapLoaded) {
      addGisLayers();
    }
  }, [mapLoaded, layers]);

  const addGisLayers = () => {
    layers
      .filter(
        (x) => _.toLower(x.sourceType) == _.toLower(MapSourceTypes.ArcGis)
      )
      .forEach((layer) => {
        const fsSourceId = "source_" + layer.key;
        const service = new FeatureService(
          fsSourceId,
          mapRef.current.getMap(),
          {
            url: layer.url,
          }
        );
        mapRef.current.getMap().addLayer({
          id: layer.key,
          source: fsSourceId,
          type: _.toLower(layer.layerType),
          paint: JSON.parse(layer.paintJson),
          layout: {
            visibility: enabledLayers.includes(layer.key) ? "visible" : "none",
          },
        });
        return () => {
          service.destroySource();
        };
      });
  };

  useEffect(() => {
    let savedViewport = localStorageService.getLocalStorage(
      LocalStorageKeys.DashboardMapViewport
    );

    if (savedViewport) {
      setViewState(savedViewport);
    }
  }, []);

  const prepareMainDashboard = () => {
    prepEnabledLayers();
    getAssetLayers()
      .then((res) => {
        setAssetLayers(res.data);
        var assetFilterLs = localStorageService.getLocalStorage(
          LocalStorageKeys.MapFilterSelectedAssets
        );
        if (assetFilterLs) {
          var assetsToSet = res.data.filter((x) =>
            assetFilterLs.includes(x.id)
          );
          setSelectedAssets(assetsToSet);
        } else {
          setSelectedAssets(res.data);
        }
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
    if (hasAnyMonitoringRole()) {
      getMonitoringEvents().then((res) => {
        setMonitoringEvents(res.data);
      });
      getMonitoringLocations().then((res) => {
        setMonitoringLocations(res.data);
        getLookupsTrigger({lookupType: LookupTypes.MonitoringLocationType}).then((res2) => {
          var monLocTypes = res2.data;
          setMonitoringLocationTypes(monLocTypes);
          var selectedTypesLs = localStorageService.getLocalStorage(
            LocalStorageKeys.MapFilterSelectedMonitoringLocationTypes
          );

          if (selectedTypesLs) {
            setSelectedTypes(
              monLocTypes.filter((x) => selectedTypesLs.includes(x.id))
            );
            setFilteredMonitoringLocations(
              res.data.filter((x) =>
                selectedTypesLs.includes(x.monitoringLocationTypeId)
              )
            );
          } else {
            setSelectedTypes(monLocTypes);
            setFilteredMonitoringLocations(res.data);
          }
        });
      });
    }
    let layersToSet = [];
    getMapLayers().then((res) => {
      setLayers(res.data);

      layersToSet = [...res.data];

      layersToSet = [
        ...layersToSet,
        {
          name: MapLayerNames.Assets,
          key: MapLayerKeys.Assets,
          hasFilter: true,
        },
      ];
      if (hasAnyEventRole()) {
        layersToSet = [
          ...layersToSet,
          { name: MapLayerNames.Events, key: MapLayerKeys.Events },
        ];
      }
      if (hasAnyMonitoringRole()) {
        layersToSet = [
          ...layersToSet,
          {
            name: MapLayerNames.MonitoringEvents,
            key: MapLayerKeys.MonitoringEvents,
          },
          {
            name: MapLayerNames.MonitoringLocations,
            key: MapLayerKeys.MonitoringLocations,
            hasFilter: true,
          },
        ];
      }
      setLayers(_.orderBy(layersToSet, ["name"]));
    });
  };

  const prepEnabledLayers = () => {
    let enabledLayers = localStorageService.getLocalStorage(
      LocalStorageKeys.DashboardMapEnabledLayers
    );
    if (enabledLayers) {
      setEnabledLayers(enabledLayers);
    } else {
      enabledLayers = [MapLayerKeys.Events, MapLayerKeys.Assets];
      setEnabledLayers(enabledLayers);
    }
  };

  const loadEvents = () => {
    getEventsForMap({ pageSize: 1000, withLocationOnly: true })
      .then((res) => {
        const filteredEvents = res.data.filter((f) => f.latitude != 0);
        setEvents(filteredEvents);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };

  useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current.offsetWidth;
      let height = targetRef.current.offsetHeight;

      height = height - 1;

      if (toggleSidebar || !_.isEmpty(action)) {
        width = width - sidebarWidth;
      }
      setSize([width, height]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [toggleSidebar, action]);

  const handleMapClick = (e) => {
    if (!_.isEmpty(e.features)) {
      let events = e.features
        .filter((x) => x.layer.id == MapLayerKeys.Events)
        .map((x) => x.properties);
      if (!_.isEmpty(events)) {
        setSelectedData(events);
        setSidebarType(EntityTypes.Event);
        setToggleSidebar(true);
        setAction("");
        setSelectedLayer({});
      }
      let monEvents = e.features
        .filter((x) => x.layer.id == MapLayerKeys.MonitoringEvents)
        .map((x) => x.properties);

      if (!_.isEmpty(monEvents)) {
        monEvents.forEach((event) => {
          event.monitoringResults = JSON.parse(event.monitoringResults);
        });
        setSelectedData(monEvents);
        setSidebarType(EntityTypes.MonitoringEvent);
        setToggleSidebar(true);
        setAction("");
        setSelectedLayer({});
      }
      let monLocations = e.features
        .filter((x) => x.layer.id == MapLayerKeys.MonitoringLocations)
        .map((x) => x.properties);
      if (!_.isEmpty(monLocations)) {
        setSelectedData(monLocations);
        setSidebarType(EntityTypes.MonitoringLocation);
        setToggleSidebar(true);
        setAction("");
        setSelectedLayer({});
      }

      let externalFeatures = e.features
        .filter((x) =>
          layers
            .filter((x) => x.isInteractive)
            .map((x) => x.key)
            .includes(x.layer.id)
        )
        .map((x) => x.properties);

      if (!_.isEmpty(externalFeatures)) {
        setSelectedLayer(
          layers.find((x) => x.isInteractive && x.key == e.features[0].layer.id)
        );
        setSelectedData(externalFeatures);
        setSidebarType("generic");
        setToggleSidebar(true);
        setAction("");
      }
    }
  };

  const handleEventClusterClick = (e) => {
    setAction("");
    setSidebarType(EntityTypes.Event);
    setToggleSidebar(true);
    setSelectedData(e.map((x) => x.properties));
  };

  const handleMonitoringClusterClick = (e) => {
    setAction("");
    setSidebarType(EntityTypes.MonitoringEvent);
    setToggleSidebar(true);
    setSelectedData(e.map((x) => x.properties));
  };

  const handleSidebarClose = () => {
    setToggleSidebar(false);
    setSelectedData([]);
  };

  const handleEventsRefresh = () => {
    setEvents([]);
    setToggleSidebar(false);
    if (hasAnyEventRole()) {
      loadEvents();
    }
  };

  const onViewportChange = (e) => {
    const viewport = e.viewState;

    setViewState(viewport);
    delete viewport.transitionInterpolator;

    localStorageService.setLocalStorage(
      LocalStorageKeys.DashboardMapViewport,
      viewport
    );
  };

  const handleActionClick = (e, actionName) => {
    setToggleSidebar(false);
    setAction(actionName);
  };

  const handleSettingsSidebarClose = () => {
    setToggleSidebar(false);
    setAction("");
  };

  const handleLayerSelectionChange = (ids) => {
    setEnabledLayers(ids);

    localStorageService.setLocalStorage(
      LocalStorageKeys.DashboardMapEnabledLayers,
      ids
    );
  };

  const handleMapLoad = (e) => {
    mapRef.current.on("style.load", (e) => {
      addGisLayers();
      toggleVisibleLayers();
    });
    setMapLoaded(true);
  };

  const handleBaseMapStyleChange = (e) => {
    const baseStyle = mapStyles.find((x) => x.value == e.target.value);
    localStorageService.setLocalStorage(baseMapStyleKey, baseStyle);
    setBaseMapStyle(baseStyle);
  };
  const handleMonitoringLocationTypeChange = (e) => {
    const values = e.target.value;
    setSelectedTypes(values);

    const selectedTypeIds = values.map((x) => x.id);
    var newMonitoringLocations = monitoringLocations.filter((x) =>
      selectedTypeIds.includes(x.monitoringLocationTypeId)
    );
    setFilteredMonitoringLocations(newMonitoringLocations);
    localStorageService.setLocalStorage(
      LocalStorageKeys.MapFilterSelectedMonitoringLocationTypes,
      selectedTypeIds
    );
  };

  const handleAssetFilterChange = (e) => {
    const values = e.target.value;
    setSelectedAssets(values);

    const selectedAssetIds = values.map((x) => x.id);
    localStorageService.setLocalStorage(
      LocalStorageKeys.MapFilterSelectedAssets,
      selectedAssetIds
    );
  };
  const handleShowFilterDialog = (row) => {
    const selectedLayer = layers.find((x) => x.key == row.key);
    if (selectedLayer) {
      setDialogTitle(selectedLayer.name);
    }
  };
  return (
    <div
      style={{
        height: props.marginTop ? `calc(100% - ${props.marginTop})` : "100%",
        width: "100%",
      }}
      ref={targetRef}
    >
      {!_.isEmpty(viewState) && baseMapStyle && baseMapStyle.value && (
        <Map
          {...viewState}
          onMove={onViewportChange}
          onStyleData={toggleVisibleLayers}
          styleDiffing
          mapStyle={"mapbox://styles/mapbox/" + baseMapStyle.value}
          ref={mapRef}
          style={{ width: size[0], height: size[1] }}
          onLoad={handleMapLoad}
          mapboxAccessToken={MAPBOX_TOKEN}
          interactiveLayerIds={[
            ...enabledLayers.filter(
              (x) =>
                x == MapLayerKeys.Events ||
                x == MapLayerKeys.MonitoringEvents ||
                x == MapLayerKeys.MonitoringLocations
            ),
            ...layers.filter((x) => x.isInteractive).map((x) => x.key),
          ]}
          onClick={(e) => handleMapClick(e)}
        >
          <GeocoderControl
            mapboxAccessToken={MAPBOX_TOKEN}
            position="top-left"
          />
          <NavigationControl style={navControlStyle} />

          {hasAnyEventRole() && (
            <EventsLayer
              events={events}
              eventLegendKey={eventLegendKey}
              ref={mapRef}
              handleClusterClick={handleEventClusterClick}
            />
          )}
          {enabledLayers.includes(MapLayerKeys.Assets) &&
            selectedAssets.map((asset) => (
              <AssetLayer asset={asset} key={asset.name} />
            ))}
          {hasAnyMonitoringRole() && (
            <>
              <MonitoringLayer
                monitoringData={monitoringEvents}
                ref={mapRef}
                handleClusterClick={handleMonitoringClusterClick}
              />
              <MonitoringLocationsLayer
                monitoringLocations={filteredMonitoringLocations}
              />
            </>
          )}
          {mapLoaded &&
            layers
              .filter(
                (x) =>
                  _.toLower(x.sourceType) == _.toLower(MapSourceTypes.Mapbox)
              )
              .map((layer) => (
                <Source type="vector" url={layer.url}>
                  <Layer
                    type={_.toLower(layer.layerType)}
                    id={_.toString(layer.key)}
                    source-layer={layer.key}
                    paint={JSON.parse(layer.paintJson)}
                    layout={{ visibility: "visible" }}
                    key={_.toString(layer.key)}
                  />
                </Source>
              ))}
        </Map>
      )}
      {dialogTitle != null && (
        <LynxDialog
          open={dialogTitle != null}
          title={dialogTitle}
          handleConfirm={() => {
            setDialogTitle(null);
          }}
          description={
            <div>
              {dialogTitle == MapLayerNames.MonitoringLocations ? (
                <Form.Group label="Filter by Location Type(s)">
                  <MultiSelect
                    name="selectedTypes"
                    id="selectedTypes"
                    onChange={handleMonitoringLocationTypeChange}
                    value={selectedTypes}
                    dropdownValues={monitoringLocationTypes}
                    key="id"
                    label="code"
                  />
                </Form.Group>
              ) : (
                <Form.Group label="Filter Assets">
                  <MultiSelect
                    name="selectedAssets"
                    id="selectedAssets"
                    onChange={handleAssetFilterChange}
                    value={selectedAssets}
                    dropdownValues={assetLayers}
                    key="id"
                    label="name"
                  />
                </Form.Group>
              )}
            </div>
          }
        />
      )}

      {toggleSidebar && (
        <MapSidebar
          data={selectedData}
          handleClose={handleSidebarClose}
          handleEventsRefresh={handleEventsRefresh}
          type={sidebarType}
          selectedLayer={selectedLayer}
        />
      )}
      {!_.isEmpty(action) && (
        <MapSettingsSidebar
          action={action}
          handleClose={handleSettingsSidebarClose}
          layerLibrary={layers}
          enabledLayers={enabledLayers}
          handleLayerSelectionChange={handleLayerSelectionChange}
          handleBaseMapStyleChange={handleBaseMapStyleChange}
          baseMapStyle={baseMapStyle}
          mapStyles={mapStyles}
          handleShowFilterDialog={handleShowFilterDialog}
        />
      )}
      <MapLegend
        eventLegendKey={eventLegendKey}
        handleEventLegendKeyChanged={(value) => setEventLegendKey(value)}
        toggleSidebar={toggleSidebar || !_.isEmpty(action)}
      />
      <MapTopOverlay
        handleActionClick={handleActionClick}
        withSidebar={toggleSidebar || !_.isEmpty(action)}
      />
    </div>
  );
}
export const mapStyles = [
  { label: "Light", value: "light-v11" },
  { label: "Satellite Streets", value: "satellite-streets-v12" },
  { label: "Outdoors", value: "outdoors-v12" },

  { label: "Dark", value: "dark-v11" },
  { label: "Satellite", value: "satellite-v9" },
  { label: "Streets", value: "streets-v12" },
  { label: "Navigation Day", value: "navigation-day-v1" },
  { label: "Navigation Night", value: "navigation-night-v1" },
];
