
import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";

import { InspectionEventDto, LynxGridColDef, PermitDto } from "types";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
import { useGetInspectionFormsQuery } from "services/rtkApi/endpoints/inspectionForms";
import { multipleSelectContainsOperator, multipleSelectDoesNotContainOperator } from "components/data-grid/multiple-select-operator";
import { useGetPermitsQuery } from "services/rtkApi/endpoints/permits";
export const getInspectionColumns = (
  predefinedLookups: any,
  hasPermitFeature?: boolean,
): LynxGridColDef[] => {
  return [
    {
      field: "inspectionNumber",
      headerName: "Inspection #",
      width: 100,
      type: "string",
    },
    {
      field: "assetName",
      headerName: "Asset",
      width: 100,
      type: "singleSelect",
      query: useGetAssetsLookupsQuery,
      getOptionValue: (value: any) => (!value ? "" : value.name),
      getOptionLabel: (value: any) => (!value ? "" : value.name),
    },
    ...(hasPermitFeature ? [permitNamesColumn] : []),
    {
      field: "status",
      headerName: "Status",
      width: 100,
      type: "singleSelect",
      valueOptions: ["Completed", "Draft", "Overdue"],
      renderCell: (params) => (
        <div className="event-status">
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(params.row) }}
          />
          &nbsp;
          {params.value}
        </div>
      ),
    },
    {
      field: "inspectionDate",
      headerName: "Date",
      width: 150,
      type: "date",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateOnlyToLocal(value);
      },
    },
    {
      field: "inspectionFormName",
      headerName: "Form",
      width: 300,
      type: "singleSelect",
      query: useGetInspectionFormsQuery,
      queryParams: { onlyInspectionForms: true },
      getOptionValue: (value: any) => (!value ? "" : value.name),
      getOptionLabel: (value: any) => (!value ? "" : value.name),
    },
    {
      field: "equipmentName",
      headerName: "Equipment",
      width: 150,
      type: "string",
    },
    {
      field: "assignedToUserFullName",
      headerName: "Assigned To",
      width: 100,
      type: "singleSelect",
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: any) => (!value ? "" : value.fullName),
      getOptionLabel: (value: any) => (!value ? "" : value.fullName),
    },

    {
      field: "completedDateTime",
      headerName: "Completed Date",
      width: 150,
      type: "dateTime",
      valueGetter: (value) => {
        return dateUtil.convertColumnDateTimeToLocal(value);
      },
    },
    {
      field: "completedByUserFullName",
      headerName: "Completed By",
      width: 115,
      type: "singleSelect",
      valueOptions: predefinedLookups.users,
      getOptionValue: (value: any) => (!value ? "" : value.fullName),
      getOptionLabel: (value: any) => (!value ? "" : value.fullName),
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      type: "string",
    },
  ];
};
export const statusColorSwitch = (row: InspectionEventDto) => {
  switch (_.toLower(row.status)) {
    case "completed":
      return lynxColors.primary;
    case "draft":
    case "incomplete":
      return lynxColors.harvestOrange;
    case "overdue":
      return lynxColors.error;
    default:
      return lynxColors.primary;
  }
};

const permitNamesColumn: LynxGridColDef = {
  field: "permitNames",
  headerName: "Permit Names",
  width: 150,
  type: "singleSelect",
  query: useGetPermitsQuery,
  getOptionValue: (value: PermitDto) => (!value ? "" : value.permitNumber),
  getOptionLabel: (value: PermitDto) => (!value ? "" : value.permitNumber),
  filterOperators: [
    ...multipleSelectContainsOperator,
    ...multipleSelectDoesNotContainOperator,
  ],
  sortable: false,
  renderCell: (params: any) => <div>{params.value}</div>,
};