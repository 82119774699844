import { useState, useEffect } from "react";
import { Grid } from "components/lynx-components";
import "./dashboard-widgets.css";
import _ from "lodash";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import { AddWidgetModal } from "./add-widget-modal";
import { CountListWidget } from "./widgets/count-list-widget";
import { DonutChartWidget } from "./widgets/donut-chart-widget";
import "./user-dashboard.css";
import { BarChartWidget } from "./widgets/bar-chart-widget";
import {
  deleteWidget,
  handleDeleteWidgetFilterLocalStorage,
} from "../../../services/dashboard-widget-service";
import { updateWidgetSort } from "./../../../services/dashboard-widget-service";
import { LineChartWidget } from "./widgets/line-chart-widget";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";
import { useHistory } from "react-router-dom";
import useAlert from "hooks/useAlert";
import EditWidgetModal from "./edit-widget-modal";
import { useLazyGetUserDashboardWidgetsQuery } from "services/rtkApi/endpoints/dashboard";
import { WidgetTypes } from "types/enums-ts";
export function UserDashboard(props) {
  const [showAddWidget, setShowAddWidget] = useState(false);
  const [userDashboardWidgets, setUserDashboardWidgets] = useState([]);
  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const [widgetToEdit, setWidgetToEdit] = useState(null);

  const dimensions = useWindowDimensions();
  const isOffline = !useOnlineStatus();
  const history = useHistory();
  const { showAlert } = useAlert();

  const [getUserDashboardWidgetsQuery] = useLazyGetUserDashboardWidgetsQuery();

  useEffect(() => {
    loadWidgets();
    if (dimensions.isMobile && isOffline) {
      setIsOfflineMode(true);
    }
  }, []);

  const loadWidgets = () => {
    getUserDashboardWidgetsQuery().then((res) => {
      setUserDashboardWidgets(res.data);
    });
  };

  const handleAddWidget = () => {
    setShowAddWidget(true);
  };

  const handleCreateWidgetClose = (refresh) => {
    if (refresh == true) {
      loadWidgets();
    }

    setShowAddWidget(false);
    setWidgetToEdit(null);
  };

  const handleSortChange = (widgetId, isSortUp) => {
    let widgets = _.cloneDeep(userDashboardWidgets);
    let wIndex = widgets.findIndex((x) => x.id == widgetId);
    const existingSort = widgets[wIndex].sortOrder;
    const newSort = isSortUp ? existingSort - 1 : existingSort + 1;
    let widgetToChangeIndex = widgets.findIndex((x) => x.sortOrder == newSort);
    widgets[wIndex].sortOrder = newSort;
    widgets[widgetToChangeIndex].sortOrder = existingSort;
    updateWidgetSort({
      entityId: widgetId,
      newPosition: newSort,
    })
      .then((res) => {
        setUserDashboardWidgets(widgets);
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
      });
  };
  const handleDeleteWidget = (id) => {
    deleteWidget(id).then((res) => {
      showAlert("success", "Widget deleted.");
      let dWidgets = _.cloneDeep(userDashboardWidgets);
      let index = dWidgets.findIndex((x) => x.id == id);

      dWidgets.splice(index, 1);
      _.orderBy(dWidgets, "sortOrder").forEach((widget, index) => {
        widget.sortOrder = index + 1;
      });
      setUserDashboardWidgets(dWidgets);
    });
    handleDeleteWidgetFilterLocalStorage(id);
  };

  const prepareDashboardWidget = (widget, index) => {
    const isSystem = Boolean(widget.dashboardSystemWidgetId);
    switch (_.toLower(widget.widgetType)) {
      case WidgetTypes.CountList.toLowerCase():
        return (
          <CountListWidget
            widget={widget}
            handleSortChange={handleSortChange}
            handleDelete={handleDeleteWidget}
            upDisabled={index == 0}
            downDisabled={index == userDashboardWidgets.length - 1}
            handleUpdate={
              isSystem ? undefined : (widget) => setWidgetToEdit(widget)
            }
            {...props}
          />
        );
      case WidgetTypes.DonutChart.toLowerCase():
        return (
          <DonutChartWidget
            widget={widget}
            handleSortChange={handleSortChange}
            handleDelete={handleDeleteWidget}
            upDisabled={index == 0}
            downDisabled={index == userDashboardWidgets.length - 1}
            handleUpdate={
              isSystem ? undefined : (widget) => setWidgetToEdit(widget)
            }
            {...props}
          />
        );
      case WidgetTypes.BarChart.toLowerCase():
        return (
          <BarChartWidget
            widget={widget}
            handleSortChange={handleSortChange}
            handleDelete={handleDeleteWidget}
            upDisabled={index == 0}
            downDisabled={index == userDashboardWidgets.length - 1}
            handleUpdate={
              isSystem ? undefined : (widget) => setWidgetToEdit(widget)
            }
            {...props}
          />
        );
      case WidgetTypes.LineChart.toLowerCase():
        return (
          <LineChartWidget
            widget={widget}
            handleSortChange={handleSortChange}
            handleDelete={handleDeleteWidget}
            upDisabled={index == 0}
            downDisabled={index == userDashboardWidgets.length - 1}
            handleUpdate={
              isSystem ? undefined : (widget) => setWidgetToEdit(widget)
            }
            {...props}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Paper>
        <Grid>
          <Grid.Row className="ml-0 mr-0 subpage-header-row-mobile">
            <Grid.Col width={4}>
              <div className="d-flex h-100">
                <Typography
                  variant={dimensions.isMobile ? "h6" : "h3"}
                  component="div"
                  className="align-self-center"
                >
                  Dashboard
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col width={8}>
              {!isOfflineMode && (
                <div className="d-flex h-100 header-button">
                  <Button
                    size={dimensions.isMobile ? "small" : "medium"}
                    variant="contained"
                    className="ml-1   mr-1  align-self-center"
                    onClick={handleAddWidget}
                  >
                    Add Widget
                  </Button>
                </div>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Paper>

      {!isOfflineMode ? (
        <>
          <div className="dashboard-grid-container">
            {_.orderBy(userDashboardWidgets, "sortOrder").map(
              (widget, index) => (
                <div className="dashboard-item" key={index}>
                  {prepareDashboardWidget(widget, index)}
                </div>
              )
            )}
          </div>
          {showAddWidget && (
            <Dialog
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={showAddWidget}
              onClose={() => {
                setShowAddWidget(false);
              }}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <AddWidgetModal
                handleModalClose={handleCreateWidgetClose}
                {...props}
              />
            </Dialog>
          )}

          {widgetToEdit && (
            <Dialog
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={Boolean(widgetToEdit)}
            >
              <EditWidgetModal
                handleModalClose={handleCreateWidgetClose}
                widget={widgetToEdit}
                {...props}
              />
            </Dialog>
          )}
        </>
      ) : (
        <div className=" d-flex flex-column pl-5 pr-5">
          <Button
            variant="contained"
            className="mt-5"
            onClick={() => {
              history.push(`/add-event`);
            }}
          >
            Add Event
          </Button>
          <Button
            variant="contained"
            className="mt-5"
            onClick={() => {
              history.push(`/add-monitoring-event`);
            }}
          >
            Add Monitoring Event
          </Button>
        </div>
      )}
    </>
  );
}
