import {
  Paper,
  Typography,
  Breadcrumbs,
  Link,
  Container,
  Stack,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Box,
  Alert,
  IconButton,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Form, Card, Dimmer } from "components/lynx-components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/accordion";
import useAlert from "hooks/useAlert";
import { InsertDriveFile as InsertDriveFileIcon } from "@mui/icons-material";
import _ from "lodash";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import Tags from "components/dashboards/events/tags";
import {
  DocumentDto,
  EntityAttachmentDto,
  EntityLookupDto,
  RootState,
} from "types";
import { EntityTypes, UserRoles } from "types/enums";
import {
  useAddDocumentsMutation,
  useGetDocumentByIdQuery,
  useUpdateDocumentMutation,
} from "services/rtkApi/endpoints/documents";
import useWindowDimensions from "hooks/useWindowDimensions";
import { LynxDialog } from "components/lynx-dialog";
import { deleteEntityAttachment, validateFileSize } from "services/attachments";
import { useFormik } from "formik";
import * as Yup from "yup";
import { roleMatch } from "actions/auth";
import { useSelector } from "react-redux";
import { useUploadFileInChunks } from "hooks/useUploadFileInChunks";
import { uploadingManager } from "hooks/useOnlineStatus";

import CircularWithValueLabel from "components/form-controls/circular-progress-with-label";
import CloseIcon from "@mui/icons-material/Close";

interface FormState {
  items: Array<DocumentDto>;
}

const DocumentForm = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [existingDocument, setExistingDocument] =
    useState<EntityAttachmentDto | null>(null);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { showAlert } = useAlert();
  const history = useHistory();
  const dimensions = useWindowDimensions();
  const organization = useSelector((state: RootState) => state.organization);
  const [addDocument] = useAddDocumentsMutation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalUploadSize, setTotalUploadSize] = useState(0);
  const [updateDocument] = useUpdateDocumentMutation();
  const uploadFileInChunks = useUploadFileInChunks();
  const params = useParams<{ id: string }>();

  const { data: document } = useGetDocumentByIdQuery(params.id, {
    skip: !params.id,
  });
  const forUpdate = useMemo(() => {
    return Boolean(params.id);
  }, [params.id]);

  const schema = Yup.object().shape({
    items: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Name is required."),
          file: Yup.mixed(),
          indexInAi: Yup.boolean(),
          description: Yup.string().notRequired(),
          lookupIds: Yup.array().of(Yup.number()),
        })
      )
      .min(1, "At least one document is required"),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik<FormState>({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      items: existingDocument
        ? [
            {
              entityAttachmentId: existingDocument.entityAttachmentId,
              name: existingDocument.name,
              description: existingDocument.description,
              lookupIds: existingDocument.tags?.map((m) => m.lookupId) || [],
              indexInAI: existingDocument.indexInAI,
            } as DocumentDto,
          ]
        : [],
    },
    onSubmit: () => {
      onSave();
    },
  });

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    if (validateFileSize([...files], 1024 * 1024 * 500)) {
      const docs: Array<DocumentDto> = Array.from(files).map((file) => {
        return {
          file: file,
          name: file.name,
          description: "",
          lookupIds: [],
          indexInAI: false,
        };
      });
      const newDocs = [...values.items, ...docs];
      setValues({ items: newDocs });
    } else {
      setShowFileSizeError(true);
    }
  };

  const onSave = async () => {
    setIsUploading(true);
    if (forUpdate) {
      const value = values.items[0];
      const valueToUpdate: DocumentDto = {
        entityAttachmentId: value.entityAttachmentId,
        name: value.name,
        description: value.description,
        indexInAI: value.indexInAI,
        lookupIds: value.lookupIds,
      };
      updateDocument(valueToUpdate)
        .then((res) => {
          if (res.data) {
            showAlert("success", `Document updated.`);
            setIsUploading(false);
            history.push("/documents");
          }
        })
        .finally(() => setIsUploading(false));
    } else {
      if (!_.isEmpty(values.items)) {
        uploadingManager.setUploading(true);
        var totalUploadSizeCalc = values.items
          .map((x) => x.file)
          .reduce((sum, file) => sum + (file?.size || 0), 0);
        setTotalUploadSize(totalUploadSizeCalc);
        for (let index = 0; index < values.items.length; index++) {
          const onProgress = (progress: number) => {
            setUploadProgress((existing) => existing + progress); // Update state for UI
          };
          const file = values.items[index].file;
          const clonedItem = { ...values.items[index], file: null };
          const dataString = JSON.stringify(clonedItem);
          await uploadFileInChunks(file, dataString, onProgress)
            .then((res) => {

              if (index == values.items.length - 1) {
                showAlert("success", `Document(s) uploaded.`);
                setIsUploading(false);
                history.push("/documents");
                uploadingManager.setUploading(false);
              }
            })
            .catch((err) => {
              uploadingManager.setUploading(false);
            });
        }
      }
    }
  };

  const onDelete = () => {
    deleteEntityAttachment(params.id).then((res) => {
      if (res) {
        showAlert("success", `Document deleted.`);
        setIsDeleteOpen(false);
        history.push("/documents");
      }
    });
  };

  const handleRemoveFile = (index: number) => {
    const newItems = values.items.filter((_, i) => i !== index);
    setValues({ items: newItems });
  };

  useEffect(() => {
    if (!params.id) {
      setIsLoading(false);
    } else {
      if (document) {
        setExistingDocument(document);
        setIsLoading(false);
      }
    }
  }, [document]);

  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {forUpdate ? "Edit Document" : "Upload Documents"}
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => history.push("/documents")}
                >
                  Documents
                </Link>
                <Typography color="text.primary">
                  {forUpdate ? "Edit Document" : "Upload Documents"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>

        <Grid.Row>
          <Container className="mt-2" maxWidth="xl">
            <form onSubmit={handleSubmit}>
              <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                <Accordion
                  expanded={true}
                  onChange={() => {}}
                  className="w-100"
                >
                  <AccordionSummary
                    aria-controls="details-content"
                    id="details-header"
                  >
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>Documents</Typography>
                      {!forUpdate && (
                        <Button
                          onClick={() => hiddenFileInput.current?.click()}
                        >
                          Select Files
                        </Button>
                      )}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {values.items.length == 0 && (
                      <Alert severity="error">No documents selected.</Alert>
                    )}
                    <ImageList
                      cols={dimensions.isMobile ? 1 : forUpdate ? 1 : 3}
                      gap={8}
                      rowHeight={200}
                    >
                      {values.items.map((item, i) => {
                        return (
                          <Card
                            key={`entityAttachment_${item.entityAttachmentId}`}
                          >
                            {item.file && (
                              <>
                                {item.file.type.includes("image") ? (
                                  <ImageListItem key={"list" + _.toString(i)}>
                                    <img
                                      alt={item.file.name}
                                      src={URL.createObjectURL(item.file)}
                                      loading="lazy"
                                      key={"img" + _.toString(i)}
                                      style={{
                                        objectFit: "contain",
                                        height: 100,
                                        minWidth: 150,
                                      }}
                                    />
                                    <ImageListItemBar
                                      sx={{ background: "none" }}
                                      key={
                                        "item-action" +
                                        _.toString(item.file.name)
                                      }
                                      position="top"
                                      actionIcon={
                                        <IconButton
                                          onClick={() => handleRemoveFile(i)}
                                        >
                                          <CloseIcon color="error" />
                                        </IconButton>
                                      }
                                      actionPosition="right"
                                    />
                                    <ImageListItemBar
                                      title={item.file.name}
                                      key={"item" + _.toString(item.file.name)}
                                    />
                                  </ImageListItem>
                                ) : (
                                  <ImageListItem
                                    key={"list" + _.toString(i)}
                                    className="w-100"
                                    sx={{ border: "1px solid black" }}
                                  >
                                    <div className=" h-100 w-100 d-flex align-items-center justify-content-center">
                                      <InsertDriveFileIcon
                                        color="action"
                                        sx={{ fontSize: 150 }}
                                      />
                                    </div>
                                    <ImageListItemBar
                                      sx={{ background: "none" }}
                                      key={
                                        "item-action" +
                                        _.toString(item.file.name)
                                      }
                                      position="top"
                                      actionIcon={
                                        <IconButton
                                          onClick={() => handleRemoveFile(i)}
                                        >
                                          <CloseIcon color="error" />
                                        </IconButton>
                                      }
                                      actionPosition="right"
                                    />
                                    <ImageListItemBar
                                      title={item.file.name}
                                      key={"item" + _.toString(item.file.name)}
                                    />
                                  </ImageListItem>
                                )}
                              </>
                            )}

                            {existingDocument?.fileLocation && (
                              <>
                                {existingDocument.contentType?.includes(
                                  "image"
                                ) ? (
                                  <ImageListItem key={"list" + _.toString(i)}>
                                    <img
                                      alt={existingDocument.fileName}
                                      src={existingDocument.fileLocation}
                                      loading="lazy"
                                      key={"img" + _.toString(i)}
                                      style={{
                                        objectFit: "contain",
                                        height: 200,
                                        minWidth: 150,
                                      }}
                                    />

                                    <ImageListItemBar
                                      title={existingDocument.fileName}
                                      key={
                                        "item" +
                                        _.toString(existingDocument.fileName)
                                      }
                                    />
                                  </ImageListItem>
                                ) : (
                                  <ImageListItem
                                    key={"list" + _.toString(i)}
                                    className="w-100"
                                    sx={{ border: "1px solid black" }}
                                  >
                                    <div className=" h-100 w-100 d-flex align-items-center justify-content-center">
                                      <InsertDriveFileIcon
                                        color="action"
                                        sx={{ fontSize: 150 }}
                                      />
                                    </div>

                                    <ImageListItemBar
                                      title={existingDocument.fileName}
                                      key={
                                        "item" +
                                        _.toString(existingDocument.fileName)
                                      }
                                    />
                                  </ImageListItem>
                                )}
                              </>
                            )}

                            <Box py={2} px={1}>
                              <Grid.Col>
                                <Form.Group label="Name" isRequired>
                                  <Form.Input
                                    type="text"
                                    name={`items[${i}].name`}
                                    value={item.name}
                                    onChange={handleChange}
                                    error={
                                      !!errors.items &&
                                      !!errors.items[i] &&
                                      touched.items &&
                                      touched.items[i]
                                        ? (errors.items[i] as any).name
                                        : undefined
                                    }
                                    onBlur={handleBlur}
                                  ></Form.Input>
                                </Form.Group>
                              </Grid.Col>
                              <Grid.Col>
                                <Form.Group label="Description">
                                  <LynxTextArea
                                    autoResize
                                    name="description"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLTextAreaElement>
                                    ) =>
                                      setFieldValue(
                                        `items[${i}].description`,
                                        e.target.value
                                      )
                                    }
                                    value={item.description}
                                  ></LynxTextArea>
                                </Form.Group>
                              </Grid.Col>
                              {organization.featureFlags
                                ?.showInDevelopmentFeatures && (
                                <Grid.Col>
                                  <Form.Group label="">
                                    <Form.Checkbox
                                      label="Index in AI?"
                                      name="indexInAI"
                                      type="checkbox"
                                      checked={item.indexInAI}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `items[${i}].indexInAI`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </Grid.Col>
                              )}

                              <Grid.Col>
                                <Form.Group
                                  label="Tags"
                                  className="mb-0"
                                ></Form.Group>
                              </Grid.Col>
                              <Form.Group>
                                <Tags
                                  entityType={EntityTypes.Event}
                                  entityId={""}
                                  handleSetTags={(e: EntityLookupDto[]) => {
                                    setFieldValue(
                                      `items[${i}].lookupIds`,
                                      e.map((m) => m.lookupId)
                                    );
                                  }}
                                  disableAddTag
                                  existingTags={existingDocument?.tags}
                                />
                              </Form.Group>
                            </Box>
                          </Card>
                        );
                      })}
                    </ImageList>
                    <input
                      type="file"
                      multiple
                      ref={hiddenFileInput}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      onClick={handleInputClick}
                      accept=".csv, .xlsx, .xls, .txt, image/*, .html, video/*, audio/*, .pdf, .doc, .docx, .ppt"
                    />
                    {values.items.length > 0 && (
                      <Box display="flex" justifyContent="end" gap={2}>
                        {forUpdate && roleMatch([UserRoles.Admin]) && (
                          <Button
                            color="error"
                            variant="contained"
                            type="button"
                            onClick={() => setIsDeleteOpen(true)}
                          >
                            Delete
                          </Button>
                        )}

                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          {forUpdate ? "Save" : "Confirm Upload"}
                        </Button>
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Card.Body>
            </form>
          </Container>
          {showFileSizeError && (
            <p className="text-danger">File size exceeds the limit</p>
          )}
        </Grid.Row>
        <LynxDialog
          open={isUploading}
          title={`Uploading documents. Do not close the window.`}
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularWithValueLabel
                  value={
                    (uploadProgress / totalUploadSize) * 100 > 100
                      ? 100
                      : (uploadProgress / totalUploadSize) * 100
                  }
                />
              </div>
            </>
          }
        />
        {showFileSizeError && (
          <LynxDialog
            open={showFileSizeError}
            handleClose={() => setShowFileSizeError(false)}
            title={`File size exceeded`}
            description={
              "Documents are over the required total size limit of 500MB."
            }
            handleConfirm={() => setShowFileSizeError(false)}
          />
        )}
        <LynxDialog
          dividers
          open={isDeleteOpen}
          title={`Delete Document?`}
          description={
            "Are you sure you want to delete this Document? This action cannot be undone."
          }
          handleClose={() => {
            setIsDeleteOpen(false);
          }}
          handleConfirm={onDelete}
        />
      </Dimmer>
    </Grid>
  );
};

export default DocumentForm;
