import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import lynxColors from "../../../modules/lynxColors";
import { getAssetLookups } from "../../../services/assets";
import {
  setupInitialCustomFieldState,
  transformCustomFieldState,
} from "../../../services/custom-fields-service";
import { dateUtil } from "../../../services/date-util";
import {
  createEnergyLog,
  deleteEnergyLog,
  getEnergyLog,
  getEnergyParametersForLog,
  patchEnergyLog,
} from "../../../services/energy-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import {
  CustomFieldTypes,
  EntityTypes,
  UserRoles,
  LookupTypes,
  Statuses,
} from "../../../types/enums";
import PageNotFound from "../../PageNotFound";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LynxDialog } from "../../lynx-dialog";
import CustomField from "../events/custom-field";
import { validationService } from "./../../../services/validation";
import { AttachmentViewer } from "./../../attachment-viewer";
import { EnergyLogDetailsParameters } from "./energy-log-details-parameters";
import { BootstrapDateInput } from "../../bootstrap-inputs";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";
import { useHistory, useParams } from "react-router-dom";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

export function EnergyLogForm(props) {
  const initalFrom = {
    id: 0,
    assetId: "",
    energyLogTypeId: "",
    energyLogDate: "",
    description: "",
    energyParameters: [],
    energyLogParameters: [],
    customFieldData: {},
    status: _.toLower(Statuses.Incomplete),
  };

  const [formState, setFormState] = useState(initalFrom);
  const [initialFormState, setInitialFormState] = useState({});
  const [existingEnergyLog, setExistingEnergyLog] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [assetLookups, setAssetLookups] = useState([]);
  const [energyParameters, setEnergyParameters] = useState([]);
  const [energyLogSaving, setEnergyLogSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [energyLogLoading, setEnergyLogLoading] = useState(true);
  const [energyLogParametersLoading, setEnergyLogParametersLoading] =
    useState(true);
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [customFieldsLoading, setCustomFieldsLoading] = useState(true);
  const [energyLogTypes, setEnergyLogTypes] = useState([]);
  const [selectedEnergyLogType, setSelectedEnergyLogType] = useState({});
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [customFieldsExpanded, setCustomFieldsExpanded] = useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldState, setCustomFieldState] = useState({});
  const [existingCustomFieldValues, setExistingCustomFieldValues] =
    useState(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [showExitWithoutSaving, setShowExitWithoutSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showConfirmEnergyLogChange, setShowConfirmEnergyLogChange] =
    useState(false);

  const dimensions = useWindowDimensions();

  const { showAlert } = useAlert();
  const history = useHistory();
  const params = useParams();

  const [getLookupsTrigger] = useLazyGetLookupsQuery();

  useEffect(() => {
    if (
      !assetsLoading &&
      !energyLogLoading &&
      !energyLogParametersLoading &&
      !customFieldsLoading &&
      !_.isEmpty(formState)
    ) {
      setIsLoading(false);
    }
  }, [
    assetsLoading,
    energyLogLoading,
    energyLogParametersLoading,
    customFieldsLoading,
    formState,
  ]);

  useEffect(() => {
    if (isExistingEnergyLog()) {
      loadEnergyLog();
    } else {
      setEnergyLogParametersLoading(false);
      setEnergyLogLoading(false);
    }
    loadAssetLookups();
    loadCustomFields();
    loadEnergyLogTypes();
  }, []);

  useEffect(() => {
    if (!isExistingEnergyLog() && !_.isEmpty(customFields)) {
      let newCustomFieldState = setupInitialCustomFieldState(customFields);
      setCustomFieldState(newCustomFieldState);
    }

    if (
      isExistingEnergyLog() &&
      !_.isEmpty(customFields) &&
      existingCustomFieldValues
    ) {
      let newCustomFieldState = setupInitialCustomFieldState(
        customFields,
        existingCustomFieldValues
      );

      setCustomFieldState(newCustomFieldState);
    }
  }, [customFields, params.id, existingCustomFieldValues]);

  useEffect(() => {
    if (!_.isEmpty(customFields)) {
      let newCustomFields = [
        ...customFields.filter((x) => x.parentLookupId == null),
      ];

      newCustomFields = [
        ...newCustomFields,
        ...customFields.filter(
          (x) => x.parentLookupId == formState.energyLogTypeId
        ),
      ];

      setSelectedCustomFields(newCustomFields);
    }
  }, [formState.id, customFields, formState.energyLogTypeId]);

  const loadAssetLookups = () => {
    getAssetLookups()
      .then((res) => {
        setAssetLookups(res.data);
        setAssetsLoading(false);
      })
      .catch(() => {});
  };

  const loadEnergyParameters = (energyLogTypeId) => {
    getEnergyParametersForLog(energyLogTypeId)
      .then((res) => {
        setEnergyParameters(res.data);
      })
      .catch(() => {});
  };

  const loadEnergyLogTypes = () => {
    getLookupsTrigger({lookupType: LookupTypes.EnergyLogType}, true)
      .then((res) => {
        setEnergyLogTypes(res.data);
      })
      .catch(() => {});
  };

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.EnergyLog)
      .then((res) => {
        let customFieldsToSet = res.data.filter((x) => !x.isDeleted);
        setCustomFields(customFieldsToSet);
        setCustomFieldsLoading(false);
      })
      .catch(() => {});
  };

  const loadEnergyLog = () => {
    getEnergyLog(params.id)
      .then((res) => {
        setFormStateFromEnergyLog(res.data);
        setEnergyParameters(res.data.energyParameters);
        setExistingCustomFieldValues(res.data.eventCustomFieldValues);
        setExistingEnergyLog(res.data);
        setEnergyLogLoading(false);
        setEnergyLogParametersLoading(false);
      })
      .catch((err) => {
        setShowNotFound(true);
      });
  };

  const setFormStateFromEnergyLog = (energyLog) => {
    let newState = {
      assetId: energyLog.assetId,
      energyLogTypeId: energyLog.energyLogTypeId,
      description: energyLog.description,
      energyLogDate: dayjs(energyLog.energyLogDate),
      status: energyLog.status,
      energyLogNumber: energyLog.energyLogNumber,
    };

    setFormState(newState);
    setInitialFormState(newState);
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const handleEnergyLogDateChange = (e) => {
    let newState = { ...formState };
    _.set(newState, "energyLogDate", e);
    setFormState(newState);
  };

  const handleEnergyLogTypeChange = (e) => {
    setSelectedEnergyLogType(e.target.value);
    var paramsWithValue = energyParameters.filter((ep) => {
      return ep.parameters.some((p) => p.value > 0);
    });

    if (paramsWithValue.length > 0) {
      setShowConfirmEnergyLogChange(true);
    } else {
      setSelectedEnergyLogType(e.target.value);
      handleConfirmEnergyLogChange(e.target.value);
    }
  };

  const handleConfirmEnergyLogChange = (value) => {
    setShowConfirmEnergyLogChange(false);
    let newState = { ...formState };
    _.set(newState, "energyLogTypeId", value);
    setFormState(newState);

    if (value.length === 0) {
      setEnergyParameters([]);
    } else {
      loadEnergyParameters(value);
    }
  };

  const handleEnergyParameterInputChange = (
    parameterType,
    index,
    name,
    value
  ) => {
    let parameter = energyParameters.filter(
      (ep) => ep.parameterType === parameterType
    )[0].parameters[index];

    _.set(parameter, name, value);
    let newEnergyParameters = _.cloneDeep(energyParameters);
    setEnergyParameters(newEnergyParameters);
  };

  const handleCustomFieldChange = (e) => {
    let newState = { ...customFieldState };
    const name = e.target.name;
    const value = customFields
      .filter((x) => x.fieldType == CustomFieldTypes.Checkbox)
      .map((x) => `${x.fieldName}.booleanValue`)
      .includes(name)
      ? e.target.checked
      : customFields
          .filter((x) => x.fieldType == CustomFieldTypes.MultiSelect)
          .map((x) => `${x.fieldName}.textValue`)
          .includes(name)
      ? e.target.value.join("|")
      : e.target.value;

    _.set(newState, name, value);

    setCustomFieldState(newState);
  };

  const isExistingEnergyLog = () => {
    return params.id;
  };

  const handleExitWithoutSaving = () => {
    history.push("/energy-logs");
  };

  const handleDelete = () => {
    setShowDelete(true);
  };

  const saveForm = (saveAction) => {
    if (!validateDataForSave(saveAction)) {
      return;
    }

    let form = validationService.unsetErrors(
      formState,
      "energyLogDateError",
      "assetIdError",
      "energyLogTypeIdError"
    );

    var customFieldErrorsToUnset = customFields
      .filter((x) => x.isRequired)
      .map((field) => `${field.fieldName}Error`);

    var customFieldsToUnset = customFields
      .filter((x) => x.parentLookupId == null)
      .map((field) => `${field.fieldName}`);

    validationService.unsetErrors(
      customFieldState,
      ...customFieldErrorsToUnset
    );
    validationService.unsetFields(form, ...customFieldsToUnset);

    let formToSave = _.cloneDeep(form);
    formToSave.energyParameters = energyParameters;

    let eventCustomFieldValues = transformCustomFieldState(
      customFields,
      customFieldState,
      formState.id
    );
    formToSave.eventCustomFieldValues = eventCustomFieldValues;
    formToSave.energyLogDate = moment(formToSave.energyLogDate.$d).format(
      "YYYY-MM-01"
    );

    if (!isExistingEnergyLog()) {
      setEnergyLogSaving(true);
      createEnergyLog(formToSave, uploadedFiles, saveAction)
        .then((res) => {
          showAlert("success", "Energy log created.");
          setEnergyLogSaving(false);
          if (saveAction === "saveandexit") {
            history.push("/energy-logs");
          } else {
            setExistingEnergyLog(res.data);
            setFormStateFromEnergyLog(res.data);
            history.push(`/energy-logs/${res.data.id}`);
          }
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
          setEnergyLogSaving(false);
        });
    } else {
      initialFormState.energyLogDate = moment(
        initialFormState.energyLogDate.$d
      ).format("YYYY-MM-01");
      var diff = jsonpatch.compare(initialFormState, formToSave);
      var dtoToSave = {
        energyLogPatch: diff,
        eventCustomFieldValues: eventCustomFieldValues,
      };
      patchEnergyLog(params.id, dtoToSave, saveAction)
        .then((res) => {
          showAlert("success", "Energy log saved.");
          if (saveAction === "saveandexit") {
            history.push("/energy-logs");
          } else {
            setExistingEnergyLog(res.data);
            setFormStateFromEnergyLog(res.data);
          }
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
          setEnergyLogSaving(false);
        });
    }
  };

  const validateDataForSave = (saveAction) => {
    let newState = { ...formState };
    let newCustomFieldState = { ...customFieldState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "energyLogDate",
      "energyLogDateError",
      "Energy Log Date"
    );

    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    validationService.validateRequiredField(
      newState,
      "energyLogTypeId",
      "energyLogTypeIdError",
      "Energy Log Type"
    );

    let errorFields = [
      "energyLogDateError",
      "assetIdError",
      "energyLogTypeIdError",
    ];

    if (saveAction === "submit") {
      var hasParams = false;

      for (let energyParameter of energyParameters) {
        var paramsWithValue = energyParameter.parameters.filter(
          (parameter) => parameter.value > 0
        );

        if (paramsWithValue.length > 0) {
          hasParams = true;
          break;
        }
      }

      if (!hasParams) {
        showAlert(
          "error",
          "Form is not valid for submitting until at least one parameter has a value."
        );
        return false;
      }

      customFields
        .filter(
          (x) =>
            x.isRequired &&
            !x.isDeleted &&
            (_.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Dropdown) ||
              _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Text) ||
              _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.TextArea) ||
              _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.MultiSelect))
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.textValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      customFields
        .filter(
          (x) =>
            x.isRequired &&
            !x.isDeleted &&
            _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.DateTime)
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateTimeValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });
      customFields
        .filter(
          (x) =>
            x.isRequired &&
            !x.isDeleted &&
            _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Date)
        )
        .forEach((value) => {
          validationService.validateRequiredField(
            newCustomFieldState,
            `${value.fieldName}.dateValue`,
            `${value.fieldName}Error`,
            `${value.fieldLabel}`
          );
        });

      customFields
        .filter(
          (x) => _.toLower(x.fieldType) == _.toLower(CustomFieldTypes.Number)
        )
        .forEach((value) => {
          validationService.validateNumberField(
            newCustomFieldState,
            `${value.fieldName}.numericValue`,
            `${value.fieldName}Error`,
            value.isRequired,
            `${value.fieldLabel}`
          );
        });

      var customFieldErrorsToUnset = selectedCustomFields
        .filter((x) => x.isRequired)
        .map((field) => `${field.fieldName}Error`);

      isFormValid =
        !validationService.hasError(newState, ...errorFields) &&
        !validationService.hasError(
          newCustomFieldState,
          ...customFieldErrorsToUnset
        );
      if (!isFormValid) {
        setFormState(newState);
        setCustomFieldState(newCustomFieldState);
        showAlert("error", "Form is not valid for submitting.");
      }
    } else {
      isFormValid = !validationService.hasError(newState, ...errorFields);
      if (!isFormValid) {
        setFormState(newState);
        showAlert("error", "Form is not valid for saving.");
      }
    }

    return isFormValid;
  };

  const deleteItem = () => {
    deleteEnergyLog(existingEnergyLog.id).then(() => {
      showAlert("success", `Energy log deleted`);
      history.push("/energy-logs");
    });
  };

  if (showNotFound) {
    return <PageNotFound message="Energy Log not found" />;
  } else
    return (
      <Grid>
        <Dimmer active={isLoading} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                <div className="d-flex">
                  <Typography variant="h3" component="div">
                    {isExistingEnergyLog()
                      ? existingEnergyLog.energyLogNumber
                      : "Add Energy Log"}
                  </Typography>
                  {isExistingEnergyLog() && (
                    <div className="align-self-center ml-5">
                      {getStatusChip(existingEnergyLog)}
                    </div>
                  )}
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12} className="">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={(e) => handleNavigateTo(e, "/energy-logs")}
                  >
                    Energy Logs
                  </Link>
                  <Typography color="text.primary">
                    {isExistingEnergyLog()
                      ? existingEnergyLog.energyLogNumber
                      : "Add Energy Log"}
                  </Typography>
                </Breadcrumbs>
              </Grid.Col>
            </Grid.Row>
          </Paper>
          <Grid.Row>
            <Container className="form-container" maxWidth="xl">
              <Form className="card ">
                <Grid.Row>
                  <Accordion
                    expanded={detailsExpanded}
                    onChange={() => setDetailsExpanded(!detailsExpanded)}
                    className="w-100"
                  >
                    <AccordionSummary
                      aria-controls="details-content"
                      id="details-header"
                    >
                      <Typography>Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Asset">
                          <Form.Select
                            name="assetId"
                            onChange={handleInputChange}
                            value={formState.assetId}
                            error={formState.assetIdError}
                          >
                            <option value={""}></option>
                            {assetLookups.map((asset) => (
                              <option value={asset.id} key={asset.id}>
                                {asset.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Energy Log Type">
                          <Form.Select
                            name="energyLogTypeId"
                            onChange={handleEnergyLogTypeChange}
                            value={formState.energyLogTypeId}
                            error={formState.energyLogTypeIdError}
                            disabled={
                              _.toLower(formState.status) ==
                              _.toLower(Statuses.Complete)
                            }
                          >
                            <option value={""}></option>
                            {energyLogTypes.map((elt) => (
                              <option value={elt.id} key={elt.id}>
                                {elt.code}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Energy Log Date">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <BootstrapDateInput
                              name="energyLogDate"
                              views={["year", "month"]}
                              minDate={dayjs("2000-03-01")}
                              maxDate={dayjs("2050-06-01")}
                              value={formState.energyLogDate}
                              error={formState.energyLogDateError}
                              onChange={handleEnergyLogDateChange}
                            />
                          </LocalizationProvider>

                          {formState.energyLogDateError && (
                            <Grid.Row>
                              <span
                                className="invalid-feedback"
                                style={{ display: "inline" }}
                              >
                                {formState.energyLogDateError}
                              </span>
                            </Grid.Row>
                          )}
                        </Form.Group>
                      </Grid.Col>

                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Description">
                          <LynxTextArea
                            name="description"
                            onChange={handleInputChange}
                            value={formState.description}
                          ></LynxTextArea>
                        </Form.Group>
                      </Grid.Col>
                    </AccordionDetails>
                  </Accordion>
                  {energyParameters.map((ep, i) => {
                    return (
                      <EnergyLogDetailsParameters
                        key={i}
                        energyParameters={ep.parameters}
                        parameterType={ep.parameterType}
                        handleEnergyParameterInputChange={
                          handleEnergyParameterInputChange
                        }
                      ></EnergyLogDetailsParameters>
                    );
                  })}
                  {!_.isEmpty(selectedCustomFields) && (
                    <Accordion
                      expanded={customFieldsExpanded}
                      onChange={() =>
                        setCustomFieldsExpanded(!customFieldsExpanded)
                      }
                      className="w-100"
                    >
                      <AccordionSummary
                        aria-controls="custom-fields-content"
                        id="custom-fields-header"
                      >
                        <Typography>Additional Fields</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid.Row>
                          {selectedCustomFields
                            .sort((a, b) => (a.position > b.position ? 1 : -1))
                            .filter((x) => !x.isDeleted)
                            .map((field) => (
                              <CustomField
                                customField={field}
                                key={field.position}
                                isRequired={field.isRequired}
                                handleInputChange={handleCustomFieldChange}
                                formState={customFieldState}
                              ></CustomField>
                            ))}
                        </Grid.Row>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Grid.Row>
                <Card.Footer>
                  <div class={dimensions.isMobile ? "" : "float-right"}>
                    <Button
                      className="mr-2"
                      variant="contained"
                      onClick={() => saveForm("save")}
                    >
                      Save
                    </Button>
                    {_.toLower(formState.status) ==
                      _.toLower(Statuses.Incomplete) && (
                      <Button
                        variant="contained"
                        color="success"
                        className="mr-2"
                        onClick={() => saveForm("submit")}
                      >
                        Submit
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => saveForm("saveandexit")}
                      className="mr-2"
                    >
                      Save and Exit
                    </Button>
                  </div>
                  <div class={dimensions.isMobile ? "mt-2" : "float-left"}>
                    <Button
                      variant="contained"
                      color="error"
                      className="mr-2"
                      onClick={() => setShowExitWithoutSaving(true)}
                    >
                      Exit Without Saving
                    </Button>
                    {roleMatch([UserRoles.Admin]) && isExistingEnergyLog() && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </Card.Footer>
              </Form>
              <Grid.Row>
                <Grid.Col
                  md={isExistingEnergyLog() ? 6 : 12}
                  width={12}
                  className="pl-0"
                >
                  <div className="energyLog-details-card">
                    <AttachmentViewer
                      isExistingEntity={isExistingEnergyLog()}
                      entityType={EntityTypes.EnergyLog}
                      disableDelete={
                        _.toLower(existingEnergyLog.status) == "closed"
                      }
                      entityId={existingEnergyLog.id}
                      handleSetUploadedFiles={(files) => {
                        setUploadedFiles(files);
                      }}
                      rowHeight={300}
                    />
                  </div>
                </Grid.Col>
                {isExistingEnergyLog() && (
                  <Grid.Col md={6} width={12} className="pr-0">
                    {getHistoryCard(existingEnergyLog)}
                  </Grid.Col>
                )}
              </Grid.Row>
              <div style={{ width: "50%" }}></div>{" "}
            </Container>
          </Grid.Row>
          {energyLogSaving && (
            <LynxDialog
              open={energyLogSaving}
              title="Saving Energy Log"
              description={
                <>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <CircularProgress />
                  </div>
                </>
              }
            />
          )}
          <LynxDialog
            open={showExitWithoutSaving}
            handleConfirm={handleExitWithoutSaving}
            handleClose={() => setShowExitWithoutSaving(false)}
            title="Exit without saving?"
            description="Are you sure that you want to exit without saving your changes?"
          />

          <LynxDialog
            open={showDelete}
            handleClose={() => setShowDelete(false)}
            handleDelete={deleteItem}
            title="Delete Energy Log"
            description={`Are you sure that you want to delete Energy Log ${formState.energyLogNumber}`}
          />

          <LynxDialog
            open={showConfirmEnergyLogChange}
            handleConfirm={() =>
              handleConfirmEnergyLogChange(selectedEnergyLogType)
            }
            handleClose={() => setShowConfirmEnergyLogChange(false)}
            title="Change Energy Log Type?"
            description="Are you sure that you want to change the energy log type? This will remove previously input energy parameter values."
          />
        </Dimmer>
      </Grid>
    );
}
export const getStatusChip = (energyLog, size) => {
  if (_.toLower(energyLog.status) == _.toLower(Statuses.Incomplete)) {
    return (
      <Chip
        label={Statuses.Incomplete}
        size={size}
        sx={{ backgroundColor: lynxColors.error, color: "white" }}
      />
    );
  } else if (_.toLower(energyLog.status) == _.toLower(Statuses.PendingReview)) {
    return (
      <Chip
        label={Statuses.PendingReview}
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  } else if (_.toLower(energyLog.status) == _.toLower(Statuses.Complete)) {
    return <Chip label={Statuses.Complete} size={size} color="primary" />;
  }

  return (
    <Chip
      label={Statuses.Incomplete}
      size={size}
      sx={{ backgroundColor: lynxColors.error, color: "white" }}
    />
  );
};

function getHistoryCard(existingEnergyLog) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>History</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <TableContainer>
          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="right" width="33%">
                  Created By:
                </TableCell>
                <TableCell>{existingEnergyLog.createdByFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingEnergyLog.createdDateTimeUtc
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Submitted By:</TableCell>
                <TableCell>{existingEnergyLog.submittedByFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingEnergyLog.submittedDateTimeUtc
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Reviewed/Closed By:</TableCell>
                <TableCell>{existingEnergyLog.reviewedByFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingEnergyLog.reviewedDateTimeUtc
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
  );
}
