import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getEnergyLogExportAsXLSX
} from "../../../services/energy-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import { LynxDataGrid } from "../../data-grid/lynx-data-grid";
import { energyLogColumns } from "./energy-log-columns";
import { transformCustomFieldDefsToColumnDefs } from "../../../services/custom-fields-service";
import { EnergyLogMobileCard } from "./energy-log-mobile-card";
import { EventCustomFieldDto, LynxGridColDef, RootState } from "types";
import { EntityTypes } from "types/enums-ts";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useGetEnergyLogsQuery } from "services/rtkApi/endpoints/energyLogs";

export function EnergyLogList() {
  const history = useHistory();
  var account = useSelector((state: RootState) => state.account);
  const [columns, setColumns] = useState<LynxGridColDef[]>([]);
  const [eventCustomFields, setEventCustomFields] = useState<
    EventCustomFieldDto[] | null
  >(null);
  const { users } = useSelector((state: RootState) => state.lookups);
  const predefinedLookups = { users: users };
  useEffect(() => {
    let newColumns = [...energyLogColumns(predefinedLookups)];

    let colIndex = newColumns.findIndex((x) => x.field == "energyLogNumber");
    newColumns[colIndex].renderCell = (params: any) => {
      return (
        <Button
          variant="text"
          className="energy-log-number-button"
          onClick={() => handleEnergyLogNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [energyLogColumns]);

  useEffect(() => {
    getCustomFields(EntityTypes.EnergyLog)
      .then((res) => {
        let eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch((err) => {
        setColumns(energyLogColumns(predefinedLookups));
      });
  }, []);

  useEffect(() => {
    if (eventCustomFields != null && !account.isLoading) {
      let newColumns = [
        ...energyLogColumns(predefinedLookups),
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      setColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading]);

  const navigateToAddEnergyLog = () => {
    history.push(`/add-energy-log`);
  };

  const handleEnergyLogNumberClick = (row: any) => {
    history.push(`/energy-logs/${row.id}`);
  };

  return (
    <>
      {!_.isEmpty(columns) && (
        <LynxDataGrid
          enableSavedFilters
          columns={columns}
          useQuery={useGetEnergyLogsQuery}
          title="Energy Logs"
          localStorageName="energyLogs"
          entityType={EntityTypes.EnergyLog}
          addButtonAction={navigateToAddEnergyLog}
          addButtonText="Add Energy Log"
          mobileCard={EnergyLogMobileCard}
          handleExportXLSX={getEnergyLogExportAsXLSX}
        />
      )}
    </>
  );
}
