import { useEffect, useMemo, useState } from "react";

import Button from "@mui/material/Button";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Form, Grid, Icon } from "components/lynx-components";
import { loadAccount } from "../../../actions/account";
import { loadUserOrg } from "../../../actions/organization";
import { validationService } from "../../../services";
import {
  addUserAdmin,
  getUserToken,
  updateUserAdmin,
} from "../../../services/admin";
import { LocalStorageKeys, UserRoles } from "./../../../types/enums";
import { LynxDialog } from "./../../lynx-dialog";
import useAlert from "hooks/useAlert";
import {
  useGetEventRolesQuery,
  useGetMonitoringRolesQuery,
} from "services/rtkApi/endpoints/roles";
import { MultiSelect } from "components/form-controls/multi-select";
import { useLazyGetLookupQuery } from "services/rtkApi/endpoints/lookups";
export function AdminUserModal(props) {
  const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    isAdmin: false,
    isActive: true,
    isInternal: false,
    timezoneId: "Mountain Standard Time",
    coordinateType: "Utm",
    role: "regular",
    eventRole: "",
  };
  const [formState, setFormState] = useState(initialState);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [registrationGuid, setRegistrationGuid] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const monitoringRolesQuery = useGetMonitoringRolesQuery();
  const { showAlert } = useAlert();
  const eventRolesQuery = useGetEventRolesQuery();
  const [organizations, setOrganizations] = useState([]);
  const [getLookupTrigger] = useLazyGetLookupQuery();
  
  useEffect(() => {
    getLookupTrigger("timezone").then((res) => {
      setTimezones(res.data);
    });
  }, []);
  useEffect(() => {
    if (eventRolesQuery.data && props.entity.userRoles) {
      const currentRole = eventRolesQuery.data.filter((role) => {
        return props.entity.userRoles.some((f) => f == role);
      });

      setFormState((prev) => ({ ...prev, eventRole: currentRole[0] }));
    }
  }, [eventRolesQuery.data, props.entity.userRoles]);
  useEffect(() => {
    if (!_.isEmpty(props.entity)) {
      setFormStateFromProps(props.entity);
    }
  }, [props.entity]);
  useEffect(() => {
    if (monitoringRolesQuery.data && props.entity.userRoles) {
      const currentRole = monitoringRolesQuery.data.filter((role) => {
        return props.entity.userRoles.some((f) => f == role);
      });
      setFormState((prev) => ({ ...prev, monitoringRole: currentRole[0] }));
    }
  }, [monitoringRolesQuery.data, props.entity.userRoles]);
  useEffect(() => {
    if (
      !_.isEmpty(props.selectedCustomer.organizations) &&
      !_.isEmpty(props.entity)
    ) {
      var orgsToSet = props.selectedCustomer.organizations.filter((org) => {
        return props.entity.organizationUsers
          .map((a) => a.organizationId)
          .includes(org.id);
      });
      setOrganizations(orgsToSet);
    }
  }, [props.entity, props.selectedCustomer.organizations]);
  const monitoringRoles = useMemo(() => {
    if (monitoringRolesQuery.data) {
      return monitoringRolesQuery.data.map((m, i) => ({
        id: m,
        role: m.replace("Monitoring", ""),
      }));
    } else {
      return [];
    }
  }, [monitoringRolesQuery.data]);
  const eventRoles = useMemo(() => {
    if (eventRolesQuery.data) {
      return eventRolesQuery.data.map((m, i) => ({
        id: m,
        role: m.replace("Events", ""),
      }));
    } else {
      return [];
    }
  }, [eventRolesQuery.data]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      name === "isActive" ||
      name === "isInternal" ||
      name === "isIncidentsUser" ||
      name === "isMonitoringUser" ||
      name === "isEventsUser" ||
      name === "isEnergyUser" ||
      name === "isEnergyReviewer" ||
      name === "isInspectionsUser" ||
      name === "isTimeSeriesUser" ||
      name == "hasEventConfidentialAccess" ||
      name == "hasMonitoringConfidentialAccess"
        ? e.target.checked
        : e.target.value;

    _.set(newState, name, value);
    setFormState(newState);
  };

  const setFormStateFromProps = (user) => {
    let newState = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      isAdmin: user.isAdmin,
      isActive: user.isActive,
      isInternal: user.isInternal,
      timezoneId: user.timezoneId,
      coordinateType: user.coordinateType,
      isIncidentsUser: user.isIncidentsUser,
      isMonitoringUser: user.isMonitoringUser,
      isInspectionsUser: user.isInspectionsUser,
      isEventsUser: user.isEventsUser,
      isEnergyUser: user.isEnergyUser,
      isEnergyReviewer: user.isEnergyReviewer,
      isTimeSeriesUser: user.isTimeSeriesUser,
      hasEventConfidentialAccess: user.hasEventConfidentialAccess,
    };
    if (user.userRoles.find((x) => x == "Regular")) {
      newState.role = "regular";
    }

    if (user.userRoles.find((x) => x == UserRoles.Admin)) {
      newState.role = "admin";
    }

    if (user.userRoles.find((x) => x == UserRoles.SuperAdmin)) {
      newState.role = "superAdmin";
    }

    setFormState((prev) => ({ ...prev, ...newState }));
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "firstNameError",
      "lastNameError",
      "timezoneIdError",
      "coordinateTypeError",
      "roleError",
      "emailError"
    );
    formToSave.organizations = organizations;
    if (!_.isEmpty(props.entity)) {
      updateUserAdmin(props.entity.id, formToSave)
        .then((res) => {
          showAlert("success", "User updated.");
          props.handleModalClose(true);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    } else {
      addUserAdmin({
        ...formToSave,
        organizationId: props.organizationId,
        customerId: props.selectedCustomer.id,
      })
        .then((res) => {
          setShowAddDialog(true);
          setRegistrationGuid(res.data.registrationGuid);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
    //unset errors
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First name"
    );
    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last name"
    );
    validationService.validateRequiredField(
      newState,
      "timezoneId",
      "timezoneIdError",
      "Timezone"
    );
    validationService.validateRequiredField(
      newState,
      "coordinateType",
      "coordinateTypeError",
      "Coordinate Type"
    );
    validationService.validateRequiredField(
      newState,
      "role",
      "roleError",
      "Permissions"
    );
    validationService.validateRequiredField(
      newState,
      "email",
      "emailError",
      "Email"
    );

    isFormValid = !validationService.hasError(
      newState,
      "firstNameError",
      "lastNameError",
      "timezoneIdError",
      "coordinateTypeError",
      "roleError",
      "emailError"
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleLoginAsUser = () => {
    getUserToken(props.entity.id).then((res) => {
      localStorage.setItem(LocalStorageKeys.AccessToken, res.data.token);
      dispatch(loadAccount());
      dispatch(loadUserOrg());
      history.push("/dashboard");
    });
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          {!_.isEmpty(props.entity) ? "Edit User" : "Add User"}

          <Icon
            name="x"
            onClick={() => props.handleModalClose(false)}
            className="float-right pointer"
          ></Icon>
        </Card.Title>

        <Grid.Row>
          <>
            {" "}
            <Grid.Col md={12} width={12}>
              <Form.Group label="Email" isRequired>
                <Form.Input
                  disabled={!_.isEmpty(props.entity)}
                  name="email"
                  onChange={handleInputChange}
                  value={formState.email}
                  error={formState.emailError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="First name" isRequired>
                <Form.Input
                  name="firstName"
                  error={formState.firstNameError}
                  onChange={handleInputChange}
                  value={formState.firstName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Last name" isRequired>
                <Form.Input
                  name="lastName"
                  error={formState.lastNameError}
                  onChange={handleInputChange}
                  value={formState.lastName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12} md={12}>
              <Form.Group isRequired label="Timezone">
                <Form.Select
                  value={formState.timezoneId}
                  name="timezoneId"
                  onChange={handleInputChange}
                  error={formState.timezoneIdError}
                >
                  <option value={""}></option>
                  {timezones.map((zone) => (
                    <option value={zone.id}>{zone.displayName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col sm={12} md={12}>
              <Form.Group label="Coordinate Type" isRequired>
                <Form.Select
                  value={formState.coordinateType}
                  name="coordinateType"
                  onChange={handleInputChange}
                  error={formState.coordinateTypeError}
                >
                  <option value="LatLong">Latitude / Longitude</option>
                  <option value="Utm">UTM</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Permissions" isRequired>
                <Form.Select
                  name="role"
                  error={formState.roleError}
                  value={formState.role}
                  onChange={handleInputChange}
                >
                  <option value={"regular"}>Regular user</option>
                  <option value={"admin"}>Admin user</option>
                  <option value={"superAdmin"}>Super admin user</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Organizations">
                <MultiSelect
                  name="organizations"
                  onChange={(e) => {
                    setOrganizations(e.target.value);
                  }}
                  value={organizations}
                  dropdownValues={props.selectedCustomer.organizations}
                  key="id"
                  label="name"
                  id="selectedCustomers"
                  error={formState.organizationsError}
                />
              </Form.Group>
            </Grid.Col>
            {!_.isEmpty(props.entity) && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Active"
                    name="isActive"
                    onChange={handleInputChange}
                    checked={formState.isActive}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            <Grid.Col md={12} width={12}>
              <Form.Group>
                <Form.Checkbox
                  label="Internal"
                  name="isInternal"
                  onChange={handleInputChange}
                  checked={formState.isInternal}
                />
              </Form.Group>
            </Grid.Col>
            {props.selectedCustomer.energyLogsLicenseCount > 0 && (
              <>
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Energy User"
                      name="isEnergyUser"
                      onChange={handleInputChange}
                      checked={formState.isEnergyUser}
                    />
                  </Form.Group>
                </Grid.Col>
                {formState.isEnergyUser && (
                  <Grid.Col md={12} width={12}>
                    <Form.Group className="ml-1">
                      <Form.Checkbox
                        label="Energy Reviewer"
                        name="isEnergyReviewer"
                        onChange={handleInputChange}
                        checked={formState.isEnergyReviewer}
                      />
                    </Form.Group>
                  </Grid.Col>
                )}
              </>
            )}
            {props.selectedCustomer.eventsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Events User"
                    name="isEventsUser"
                    onChange={handleInputChange}
                    checked={formState.isEventsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {formState.isEventsUser && (
              <Grid.Col md={12} width={12}>
                <Form.Group label="Event Permissions">
                  <Card>
                    <Card.Body className="p-3">
                      <Form.Group>
                        <Form.Checkbox
                          label="Confidential Access?"
                          name="hasEventConfidentialAccess"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          checked={formState.hasEventConfidentialAccess}
                        />
                      </Form.Group>
                      <Form.Group label="Event Role" isRequired>
                        <Form.Select
                          name="eventRole"
                          value={formState.eventRole}
                          onChange={handleInputChange}
                          error={formState.eventRoleError}
                        >
                          <option value={""}></option>
                          {eventRoles.map((role) => (
                            <option value={role.id}>{role.role}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Form.Group>
              </Grid.Col>
            )}
            {props.selectedCustomer.incidentsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Incidents User"
                    name="isIncidentsUser"
                    onChange={handleInputChange}
                    checked={formState.isIncidentsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {props.selectedCustomer.inspectionsLicenseCount > 0 && (
              <>
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Inspections User"
                      name="isInspectionsUser"
                      onChange={handleInputChange}
                      checked={formState.isInspectionsUser}
                    />
                  </Form.Group>
                </Grid.Col>
              </>
            )}
            {props.selectedCustomer.monitoringLicenseCount > 0 && (
              <>
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Monitoring User"
                      name="isMonitoringUser"
                      onChange={handleInputChange}
                      checked={formState.isMonitoringUser}
                    />
                  </Form.Group>
                </Grid.Col>{" "}
                {formState.isMonitoringUser && (
                  <>
                    <Grid.Col md={12} width={12}>
                      <Form.Group label="Monitoring Permissions">
                        <Card>
                          <Card.Body className="p-3">
                            <Form.Group>
                              <Form.Checkbox
                                label="Confidential Access?"
                                name="hasMonitoringConfidentialAccess"
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                                checked={
                                  formState.hasMonitoringConfidentialAccess
                                }
                              />
                            </Form.Group>
                            <Form.Group label="Monitoring Role" isRequired>
                              <Form.Select
                                name="monitoringRole"
                                value={formState.monitoringRole}
                                onChange={handleInputChange}
                                error={formState.monitoringRoleError}
                              >
                                <option value={""}></option>
                                {monitoringRoles.map((role) => (
                                  <option value={role.id}>{role.role}</option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Card.Body>

                          <Grid.Col md={12} width={12}>
                            <Form.Group>
                              <Form.Checkbox
                                label="Has Access To Time Series Module?"
                                name="isTimeSeriesUser"
                                onChange={handleInputChange}
                                checked={formState.isTimeSeriesUser}
                              />
                            </Form.Group>
                          </Grid.Col>
                        </Card>
                      </Form.Group>
                    </Grid.Col>
                  </>
                )}
              </>
            )}
            {props.selectedCustomer.timeSeriesLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Time Series User"
                    name="isTimeSeriesUser"
                    onChange={handleInputChange}
                    checked={formState.isTimeSeriesUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {!_.isEmpty(props.entity) && (
              <>
                {props.entity.registrationGuid && (
                  <Grid.Col md={12} width={12} className="mb-2">
                    <span className="text-red">
                      User is pending registration.{" "}
                      {`${window.location.origin}/register/${props.entity.registrationGuid}`}
                    </span>
                  </Grid.Col>
                )}

                <Grid.Col md={12} width={12}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setShowLoginDialog(true);
                    }}
                  >
                    Login as user
                  </Button>
                </Grid.Col>
              </>
            )}
          </>
        </Grid.Row>
      </Card.Body>
      <Card.Footer>
        <div>
          <>
            <Button
              variant="contained"
              className="float-right"
              onClick={saveForm}
            >
              {!_.isEmpty(props.entity) ? "Save User" : "Add User"}
            </Button>
          </>
        </div>
      </Card.Footer>
      {showAddDialog && (
        <LynxDialog
          open={showAddDialog}
          title="User Added!"
          description={`The user will be recieve an email with the following registration link: ${window.location.origin}/register/${registrationGuid}`}
          handleConfirm={() => {
            setShowAddDialog(false);
            props.handleModalClose(true);
          }}
        />
      )}
      {showLoginDialog && (
        <LynxDialog
          open={showLoginDialog}
          title="BE CAREFUL! LOGOUT AS SOON AS FINISHED."
          description={`This is only intended for setting up the users dashboard, use an internal login for all other purposes.`}
          handleConfirm={() => {
            setShowLoginDialog(false);
            handleLoginAsUser();
          }}
        />
      )}
    </Form>
  );
}
