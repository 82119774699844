import { Redirect, Route, Switch } from "react-router-dom";
import { RequireAuth } from "./components/PrivateRoute";
import EventList from "./components/dashboards/events/event-list";
import { AssetSettings } from "./components/dashboards/settings/AssetSettings/AssetSettings";
import { TagSettings } from "./components/dashboards/settings/ComplianceSettings/tag-settings";
import { EventSettings } from "./components/dashboards/settings/EventSettings/EventSettings";
import { Settings } from "./components/dashboards/settings/Settings";
import { ContactSettings } from "./components/dashboards/settings/contacts/contact-settings";
import { MonitoringSettings } from "./components/dashboards/settings/monitoring/monitoring-settings";
import { OrganizationSettings } from "./components/dashboards/settings/organization-settings";
import { AuditLogList } from "./components/dashboards/audit-log/audit-log-list";
import { ContactList } from "./components/dashboards/contacts/contact-list";
import { AddMonitoringEvent } from "./components/dashboards/monitoring/add-monitoring-event";
import { MonitoringLimits } from "./components/dashboards/monitoring/monitoring-limits";
import { MonitoringList } from "./components/dashboards/monitoring/monitoring-list";
import { PrivacyPolicy } from "./components/privacy";
import AccountContainer from "./containers/AccountContainer";
import LoginContainer from "./containers/LoginContainer";
import { About } from "./components/dashboards/about/about";
import { AssetList } from "./components/dashboards/assets/asset-list";
import { UserDashboard } from "./components/dashboards/dashboards/user-dashboard";
import { Imports } from "./components/dashboards/imports/imports";
import { KpiDetails } from "./components/dashboards/kpis/kpi-details";
import { KpiList } from "./components/dashboards/kpis/kpi-list";
import { DashboardMap } from "./components/dashboards/map/dashboard-map";

import PageNotFound from "./components/PageNotFound";
import { EventDetailsContainer } from "./components/dashboards/events/event-details-container";
import { NotificationSettings } from "./components/dashboards/settings/notifications/notification-settings";
import { ActionList } from "./components/dashboards/actions/action-list";
import { ForgotPassword } from "./components/login/forgot-password";
import { ResetForgotPassword } from "./components/login/reset-forgot-password";

import { OrganizationList } from "./components/admin/organization-list";
import { CommunityPortal } from "./components/community-portal/community-portal";
import { SubmissionDetails } from "./components/community-portal/submission-details";
import { SubmissionsList } from "./components/community-portal/submissions-list";
import EventForm from "./components/dashboards/events/event-form";
import { ActionSettings } from "./components/dashboards/settings/actions/actions-settings";
import { EnergyTrackingSettings } from "./components/dashboards/settings/energy/energy-tracking-settings";
import { IncidentSettings } from "./components/dashboards/settings/incidents/incident-settings";
import { ActionForm } from "./components/dashboards/actions/action-form";
import { AssetForm } from "./components/dashboards/assets/asset-form";
import { EnergyLogForm } from "./components/dashboards/energy/energy-log-form";
import { EnergyLogList } from "./components/dashboards/energy/energy-log-list";
import { ImportHistory } from "./components/dashboards/imports/import-history";
import { ImportSpecifications } from "./components/dashboards/imports/import-specifications";
import { InspectionForm } from "./components/dashboards/inspections/inspection-form";
import { InspectionList } from "./components/dashboards/inspections/inspection-list";
import { MonitoringLocationForm } from "./components/dashboards/monitoring/monitoring-location-form";
import { MonitoringVariance } from "./components/dashboards/monitoring/monitoring-variance";
import { MonitoringLocationUploadPhotos } from "./components/dashboards/time-series/monitoring-location-upload-photos";
import { RegisterAccount } from "./components/login/register-account";
import { UserRoles } from "./types/enums";

import { FormBuilder } from "./components/dashboards/settings/custom-fields/form-builder";
import { DeviceSettings } from "./components/dashboards/settings/device/device-settings";
import { InspectionSettings } from "./components/dashboards/settings/inspections/inspection-settings";
import { PermitSettings } from "./components/dashboards/settings/permits/permit-settings";
import { ContactForm } from "./components/dashboards/contacts/contact-form";

import { EquipmentList } from "./components/dashboards/equipment/equipment-list";
import { PermitForm } from "./components/dashboards/permits/permit-form";
import { PermitList } from "./components/dashboards/permits/permit-list";
import { DemoMicrosite } from "./components/microsites/demo";
import { PhotoImportHistoryReview } from "./components/dashboards/time-series/photo-import-history-review";
import { PhotoImportHistoryTraining } from "./components/dashboards/time-series/photo-import-history-training";
import { CustomerPortalSettings } from "./components/dashboards/settings/customer-portal/customer-portal-settings";
import { WylooMicrositeHome } from "./components/microsites/wyloo/wyloo-microsite-home";
import { WylooMicrositeWater } from "./components/microsites/wyloo/wyloo-microsite-water";
import { WylooLocationDetails } from "./components/microsites/wyloo/wyloo-location-details";
import { WylooMicrositeWildlife } from "components/microsites/wyloo/wyloo-microsite-wildlife";
import { WylooMicrositeAir } from "components/microsites/wyloo/wyloo-microsite-air";
import { CustomerPortalHistory } from "components/dashboards/settings/customer-portal/customer-portal-history";
import { OrganizationSelection } from "components/organization-selection";
import RubyPlayground from "components/dashboards/ai/ruby-playground";
import { CustomerList } from "components/admin/customer-list";
import IncidentsList from "components/dashboards/incidents/incidents-list";
import IncidentContainer from "components/dashboards/incidents/incident-container";
import AiPlayground from "components/dashboards/ai/ai-playground";
import DocumentList from "components/dashboards/documents/document-list";
import DocumentForm from "components/dashboards/documents/document-form";
import ComplianceList from "components/dashboards/settings/compliance/compliance-list";
import { RequirePortalAuth } from "components/portal-route";

export default function AppRoute(props) {
  return (
    <Switch>
      <Route path="/" exact component={LoginContainer} />
      <Route path="/two-factor" exact component={LoginContainer} />
      <Route path="/organizations" exact component={OrganizationSelection} />
      <Route path="/privacy" exact component={PrivacyPolicy} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/community-portal" exact component={CommunityPortal} />
      <Route path="/investor" exact component={DemoMicrosite} />
      <Route path="/wyloo" exact component={WylooMicrositeHome} />
      {/* <Route path="/ruby-ai" exact component={RubyPlayground} /> */}
      <Route path="/wyloo/water" exact component={WylooMicrositeWater} />
      <Route path="/wyloo/air" exact component={WylooMicrositeAir} />
      <Route path="/wyloo/wildlife" exact component={WylooMicrositeWildlife} />
      <Route
        path="/wyloo/monitoring-locations/:id"
        exact
        component={() => (
          <RequirePortalAuth
            roles={[UserRoles.PortalAdmin, UserRoles.PortalUser]}
          >
            <WylooLocationDetails />
          </RequirePortalAuth>
        )}
      />
      <Route
        path="/reset-forgot-password/:guid"
        exact
        component={ResetForgotPassword}
      />
      <Route path="/register/:guid" exact component={RegisterAccount} />
      <Route
        path="/account"
        exact
        component={() => (
          <RequireAuth>
            <AccountContainer />
          </RequireAuth>
        )}
      ></Route>
      <Route exact path="/authorize" component={LoginContainer} />
      <Route
        path="/dashboard"
        exact
        component={() => (
          <RequireAuth>
            <UserDashboard />
          </RequireAuth>
        )}
      />
      <Route
        path="/dashboard/home"
        exact
        component={() => (
          <RequireAuth>
            <Redirect to="/dashboard" />
          </RequireAuth>
        )}
      />
      <Route
        path="/map"
        exact
        component={() => (
          <RequireAuth>
            <DashboardMap />
          </RequireAuth>
        )}
      />
      <Route
        path="/assets"
        exact
        component={() => (
          <RequireAuth>
            <AssetList />
          </RequireAuth>
        )}
      ></Route>
      <Route
        path="/assets/:assetId"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <AssetForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-asset"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <AssetForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/dashboard/assets"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <Redirect to="/assets" {...props} />
          </RequireAuth>
        )}
      />
      <Route
        path="/events"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
              UserRoles.EventsContributor,
              UserRoles.EventsViewer,
            ]}
          >
            <EventList />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-event"
        exact
        component={() => (
          <RequireAuth
            roles={[UserRoles.EventsAdministrator, UserRoles.EventsEditor]}
          >
            <EventForm entityId={0} />
          </RequireAuth>
        )}
      />
      <Route
        path="/events/:eventId"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
              UserRoles.EventsContributor,
              UserRoles.EventsViewer,
            ]}
          >
            <EventDetailsContainer />
          </RequireAuth>
        )}
      />
      <Route
        path="/photo-import-history/:photoImportHistoryId"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.TimeSeriesUser]}>
            <PhotoImportHistoryReview />
          </RequireAuth>
        )}
      />
      <Route
        path="/photo-import-history/:photoImportHistoryId/training"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.TimeSeriesUser]}>
            <PhotoImportHistoryTraining />
          </RequireAuth>
        )}
      />
      <Route
        path="/dashboard/events"
        exact
        component={() => (
          <RequireAuth>
            <Redirect to="/events" />
          </RequireAuth>
        )}
      />
      <Route
        path="/incidents"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.IncidentsUser]}>
            <IncidentsList />
          </RequireAuth>
        )}
      />
      <Route
        path="/actions"
        exact
        component={() => (
          <RequireAuth>
            <ActionList />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-action"
        exact
        component={() => (
          <RequireAuth>
            <ActionForm />
          </RequireAuth>
        )}
      ></Route>
      <Route
        path="/actions/:id"
        exact
        component={() => (
          <RequireAuth>
            <ActionForm />
          </RequireAuth>
        )}
      ></Route>
      <Route
        path="/events/:eventId/add-action"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
              UserRoles.EventsContributor,
            ]}
          >
            <ActionForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/events/:eventId/actions/:id"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
              UserRoles.EventsContributor,
              UserRoles.EventsViewer,
            ]}
          >
            <ActionForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/submit-incident"
        exact
        roles={[UserRoles.IncidentsUser]}
        component={() => (
          <RequireAuth roles={[UserRoles.IncidentsUser]}>
            <IncidentContainer />
          </RequireAuth>
        )}
      />
      <Route
        path="/incidents/:id"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.IncidentsUser]}>
            <IncidentContainer />
          </RequireAuth>
        )}
      />
      <Route
        path="/incidents/:incidentId/add-action"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.IncidentsUser]}>
            <ActionForm />
          </RequireAuth>
        )}
      ></Route>
      <Route
        path="/incidents/:incidentId/actions/:id"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.IncidentsUser]}>
            <ActionForm />
          </RequireAuth>
        )}
      ></Route>
      <Route
        path="/add-monitoring-event"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
            ]}
          >
            <AddMonitoringEvent />
          </RequireAuth>
        )}
      />
      <Route
        path="/monitoring-locations/:monLocId/limits"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
              UserRoles.MonitoringViewer,
            ]}
          >
            <MonitoringLimits />
          </RequireAuth>
        )}
      />
      <Route
        path="/monitoring-locations/:monLocId/variance"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
              UserRoles.MonitoringViewer,
            ]}
          >
            <MonitoringVariance />
          </RequireAuth>
        )}
      />
      <Route
        path="/monitoring/:eventId"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
              UserRoles.MonitoringViewer,
            ]}
          >
            <AddMonitoringEvent />
          </RequireAuth>
        )}
      />
      <Route
        path="/monitoring"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
              UserRoles.MonitoringViewer,
            ]}
          >
            <MonitoringList />
          </RequireAuth>
        )}
      />
      <Route
        path="/monitoring-locations/:monLocId"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
              UserRoles.MonitoringViewer,
            ]}
          >
            <MonitoringLocationForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-monitoring-location"
        exact
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
            ]}
          >
            <MonitoringLocationForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/inspections"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.InspectionsUser]}>
            <InspectionList />
          </RequireAuth>
        )}
      />
      <Route
        path="/equipment"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.InspectionsUser]}>
            <EquipmentList />
          </RequireAuth>
        )}
      />
      <Route
        path="/new-inspection"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.InspectionsUser]}>
            <InspectionForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/inspections/:inspectionId"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.InspectionsUser]}>
            <InspectionForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/energy-logs"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.EnergyUser]}>
            <EnergyLogList />
          </RequireAuth>
        )}
      />
      <Route
        path="/energy-logs/:id"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.EnergyUser]}>
            <EnergyLogForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-energy-log"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.EnergyUser]}>
            <EnergyLogForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/permits"
        exact
        component={() => (
          <RequireAuth>
            <PermitList />
          </RequireAuth>
        )}
      />
      <Route
        path="/permits/:id"
        exact
        component={() => (
          <RequireAuth>
            <PermitForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-permit"
        exact
        component={() => (
          <RequireAuth>
            <PermitForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/monitoring-location-upload-photos"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.TimeSeriesUser]}>
            <MonitoringLocationUploadPhotos />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings"
        component={() => (
          <RequireAuth>
            <Settings />
          </RequireAuth>
        )}
        exact
      />
      <Route
        path="/settings/incidents"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.IncidentsUser, UserRoles.Admin]}>
            <IncidentSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/portal"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <CustomerPortalSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/portal/history"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <CustomerPortalHistory />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/events"
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.Admin,
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
            ]}
          >
            <EventSettings />
          </RequireAuth>
        )}
        exact
      />
      <Route
        path="/settings/inspections"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.InternalUser]}>
            <InspectionSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/incidents/form-builder"
        exact
        component={() => (
          <RequireAuth
            roles={[UserRoles.IncidentsUser, UserRoles.InternalUser]}
          >
            <FormBuilder />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/incidents/form-builder/:id"
        exact
        component={() => (
          <RequireAuth
            roles={[UserRoles.IncidentsUser, UserRoles.InternalUser]}
          >
            <FormBuilder />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/inspections/form-builder"
        exact
        component={() => (
          <RequireAuth
            roles={[UserRoles.InspectionsUser, UserRoles.InternalUser]}
          >
            <FormBuilder />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/inspections/form-builder/:id"
        exact
        component={() => (
          <RequireAuth
            roles={[UserRoles.InspectionsUser, UserRoles.InternalUser]}
          >
            <FormBuilder />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/assets"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <AssetSettings />
          </RequireAuth>
        )}
        exact
      />
      <Route
        path="/settings/tags"
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.Admin,
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
            ]}
          >
            <TagSettings />
          </RequireAuth>
        )}
        exact
      />
      <Route
        path="/settings/contacts"
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.Admin,
              UserRoles.EventsAdministrator,
              UserRoles.EventsEditor,
            ]}
          >
            <ContactSettings />
          </RequireAuth>
        )}
        exact
      />
      <Route
        path="/settings/monitoring"
        component={() => (
          <RequireAuth
            roles={[
              UserRoles.MonitoringAdministrator,
              UserRoles.MonitoringEditor,
              UserRoles.Admin,
            ]}
          >
            <MonitoringSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/actions"
        component={() => (
          <RequireAuth>
            <ActionSettings />
          </RequireAuth>
        )}
        exact
      />
      <Route
        path="/settings/energy-tracking"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.EnergyUser, UserRoles.Admin]}>
            <EnergyTrackingSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/permits"
        exact
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <PermitSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/contacts"
        exact
        component={() => (
          <RequireAuth>
            <ContactList />
          </RequireAuth>
        )}
      />
      <Route
        path="/add-contact"
        exact
        component={() => (
          <RequireAuth>
            <ContactForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/contacts/:contactId"
        exact
        component={() => (
          <RequireAuth>
            <ContactForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/portal-submissions"
        exact
        component={() => (
          <RequireAuth>
            <SubmissionsList />
          </RequireAuth>
        )}
      />
      <Route
        path="/portal-submissions/:id"
        exact
        component={() => (
          <RequireAuth>
            <SubmissionDetails />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/organization"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <OrganizationSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/notifications"
        component={() => (
          <RequireAuth>
            <NotificationSettings />
          </RequireAuth>
        )}
      />
      <Route
        path="/audit-log"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <AuditLogList />
          </RequireAuth>
        )}
      />
      <Route
        path="/settings/device"
        component={() => (
          <RequireAuth>
            <DeviceSettings />
          </RequireAuth>
        )}
      />
      <Route
        path={`/admin/organizations`}
        component={() => (
          <RequireAuth roles={[UserRoles.SuperAdmin]}>
            <OrganizationList />
          </RequireAuth>
        )}
      />
      <Route
        path={`/admin/customers`}
        component={() => (
          <RequireAuth roles={[UserRoles.SuperAdmin]}>
            <CustomerList />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path="/kpis"
        component={() => (
          <RequireAuth>
            <KpiList />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path="/kpis/:id"
        component={() => (
          <RequireAuth>
            <KpiDetails />
          </RequireAuth>
        )}
      />
      <Route
        path="/about"
        component={() => (
          <RequireAuth>
            <About />
          </RequireAuth>
        )}
      />
      <Route
        path="/imports"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <Imports />
          </RequireAuth>
        )}
      />
      <Route
        path="/import-history"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <ImportHistory />
          </RequireAuth>
        )}
      />
      <Route
        path="/import-configurations"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <ImportSpecifications />
          </RequireAuth>
        )}
      />
      <Route
        path="/ai"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <AiPlayground />
          </RequireAuth>
        )}
      />
      <Route
        path="/compliance"
        component={() => (
          <RequireAuth roles={[UserRoles.Admin]}>
            <ComplianceList />
          </RequireAuth>
        )}
      />
      <Route
        path="/documents"
        exact
        component={() => (
          <RequireAuth>
            <DocumentList />
          </RequireAuth>
        )}
      />
      <Route
        path="/upload-documents"
        component={() => (
          <RequireAuth>
            <DocumentForm />
          </RequireAuth>
        )}
      />
      <Route
        path="/documents/:id"
        exact
        component={() => (
          <RequireAuth>
            <DocumentForm />
          </RequireAuth>
        )}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
}
