import queryString from "query-string";
import { apiService } from "../apiService";
import { RtkTagTypes } from "types/enums";

const BASE_ROUTE = "/actions";

const actionsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getActions: builder.query<any, any>({
      query: (params) => `${BASE_ROUTE}?${queryString.stringify(params)}`,
      transformResponse: (response: any, meta: any) => {
        const pagination = meta.response.headers.get("Pagination"); // Access headers
        return { data: response, pagination }; // Include headers in the return object
      },
      providesTags: [RtkTagTypes.GetActions],
    }),
    getAction: builder.query<any, number>({
      query: (id) => `${BASE_ROUTE}/${id}`,
      providesTags: (result: any, error: any, id: any) => [
        { type: RtkTagTypes.GetActions, id },
      ],
    }),
    getActionCountByFilterId: builder.query<number, number>({
      query: (filterId) => `${BASE_ROUTE}/count/${filterId}`,
    }),
    createAction: builder.mutation<any, { dto: any; files: File[] }>({
      query: ({ dto, files }) => {
        let form = new FormData();
        files.forEach((file) => {
          form.append("file", file);
        });
        form.append("data", JSON.stringify(dto));

        return {
          url: `${BASE_ROUTE}`,
          method: "POST",
          body: form,
        };
      },
      invalidatesTags: [RtkTagTypes.GetActions],
    }),
    deleteAction: builder.mutation({
      query: (id: number) => ({
        url: `${BASE_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetActions],
    }),
    patchAction: builder.mutation({
      query: ({
        id,
        jPatch,
        saveAction,
      }: {
        id: number;
        jPatch: any;
        saveAction: any;
      }) => ({
        url: `${BASE_ROUTE}/${id}${
          saveAction ? `?saveAction=${saveAction}` : ""
        }`,
        method: "PATCH",
        body: jPatch,
      }),
      invalidatesTags: (result, error, { id }) => [
        RtkTagTypes.GetActions,
        { type: RtkTagTypes.GetActions, id },
      ],
    }),
    getActionsExportAsXLSX: builder.mutation<
      any,
      { params: any; columnDefinitionDto: any }
    >({
      query: ({ params, columnDefinitionDto }) => ({
        url: `${BASE_ROUTE}/export`,
        method: "POST",
        params: params,
        body: columnDefinitionDto,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetActionsQuery,
  useLazyGetActionsQuery,
  useLazyGetActionQuery,
  useLazyGetActionCountByFilterIdQuery,
  useCreateActionMutation,
  useDeleteActionMutation,
  usePatchActionMutation,
  useGetActionsExportAsXLSXMutation,
} = actionsEndpoint;
