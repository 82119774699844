import { apiUrl, client } from "../modules/store";

export function deleteUser(userId) {
  return client.delete(apiUrl + "/admin/users/" + userId);
}

export function updateUserAdmin(userId, dto) {
  return client.patch(apiUrl + "/admin/users/" + userId, {
    ...dto,
  });
}

export function addUserAdmin(dto) {
  return client.post(apiUrl + "/admin/users", {
    ...dto,
  });
}

export function queryRagModelAi(dto) {
  return client.post(apiUrl + "/admin/tailwinds", {
    ...dto,
  });
}
export function queryRagModelAiDemo(dto) {
  return client.post(apiUrl + "/admin/tailwinds-demo", {
    ...dto,
  });
}
export function getUserToken(id) {
  return client.get(apiUrl + "/auth/superadminlogin/" + id);
}
