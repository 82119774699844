import Container from "@mui/material/Container";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import "./incident-form.css";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { CustomFieldTypes, LookupTypes } from "../../../types/enums";
import { InspectionFormField } from "../inspections/inspection-form-field";
import {
  getErrorFieldArray,
  getFullStateFromInspectionDef,
  getInspectionForms,
  getStateFromExistingInspection,
} from "../../../services/inspection-form-service";
import { validationService } from "../../../services";
import { convertInspectionStateToValueObject } from "../../../services/inspection-form-service";
import { useSelector } from "react-redux";
import useAlert from "hooks/useAlert";
import IncidentParticipants from "./incident-participants";
import { IncidentDto } from "types";
import { RootState } from "types";
import {
  useCreateWitnessStatementMutation,
  useUpdateIncidentParticipantsMutation,
} from "services/rtkApi/endpoints/incidents";
import { useLazyGetLookupsQuery } from "services/rtkApi/endpoints/lookups";

interface IncidentInvestigationParticipantsProps {
  incident?: IncidentDto;
  updateIncident: (data: any) => void;
  isLoading: boolean;
}

const IncidentInvestigationParticipants: React.FC<
  IncidentInvestigationParticipantsProps
> = (props) => {
  const initialForm = {
    affectedPartiesError: "",
    userParticipants: [] as Array<any>,
    userWitnesses: [] as Array<any>,
    incidentParticipants: [] as Array<any>,
  };

  const { users } = useSelector((state: RootState) => state.lookups);

  const [inspectionState, setInspectionState] = useState<Record<string, any>>(
    {}
  );
  const [formState, setFormState] = useState(initialForm);
  const [affectedParties, setAffectedParties] = useState<any[]>([]);
  const [witnesses, setWitnesses] = useState<any[]>([]);
  const [incidentParticipantTypes, setIncidnetParticipantTypes] = useState<
    any[]
  >([]);
  const [witnessForm, setWitnessForm] = useState<any>([]);
  const [toggleAddStatement, setToggleAddStatement] = useState<boolean>(false);
  const [existingWitnessStatements, setExistingWitnessStatements] = useState<
    any[]
  >([]);
  const [selectedWitness, setSelectedWitness] = useState<any>({});

  const isInitiallyLocked = props.incident?.status?.toLowerCase() === "closed";
  const [isLocked, setIsLocked] = useState({
    parties: isInitiallyLocked,
    witness: isInitiallyLocked,
  });
  const [getLookupsTrigger] = useLazyGetLookupsQuery();
  const { showAlert } = useAlert();

  const [createWitnessStatementTrigger] = useCreateWitnessStatementMutation();
  const [updateIncidentParticipantsTrigger] =
    useUpdateIncidentParticipantsMutation();

  useEffect(() => {
    getInspectionForms({ onlyWitnessForms: true }).then((res) => {
      setWitnessForm(res.data[0]);
    });
    getLookupsTrigger({lookupType: LookupTypes.IncidentParticipantType}).then((res) => {
      setIncidnetParticipantTypes(res.data);
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      setAffectedParties(
        props.incident?.incidentParticipants?.filter(
          (x) => !x.userId && !x.isWitness
        ) || []
      );
      setWitnesses(
        props.incident?.incidentParticipants?.filter(
          (x) => !x.userId && x.isWitness
        ) || []
      );
    }
  }, [props.incident]);

  useEffect(() => {
    if (
      !_.isEmpty(props.incident) &&
      users &&
      !_.isEmpty(props.incident.incidentParticipants)
    ) {
      const incidentParticipants = props.incident.incidentParticipants || [];
      const witnessIds = incidentParticipants
        .filter((x) => x.isWitness && x.userId)
        .map((a) => a.userId);
      const witnessesList = users.filter((a: any) => witnessIds.includes(a.id));

      const participantIds = incidentParticipants
        .filter((x) => !x.isWitness && x.userId)
        .map((a) => a.userId);
      const participantsList = users.filter((a: any) =>
        participantIds.includes(a.id)
      );

      setFormState((existing) => ({
        ...existing,
        userParticipants: participantsList,
        userWitnesses: witnessesList,
      }));

      setExistingWitnessStatements(
        incidentParticipants.filter((x) => x.isWitness)
      );
    }
  }, [props.incident, users]);

  useEffect(() => {
    if (!_.isEmpty(witnessForm) && !_.isEmpty(selectedWitness)) {
      let fullState = getFullStateFromInspectionDef(
        witnessForm.inspectionFormFields
      );
      const existingInspectionState = getStateFromExistingInspection(
        selectedWitness.inspectionFormFieldValues
      );
      fullState = { ...fullState, ...existingInspectionState };
      setInspectionState(fullState);
    }
  }, [witnessForm, selectedWitness]);

  const handleInspectionInputChange = (
    e: any,
    sigName?: string,
    sigInput?: any
  ) => {
    const newState = { ...inspectionState };
    const name = sigName ?? e.target.name;
    const editedField = witnessForm.inspectionFormFields.find(
      (x: any) => x.name === name
    );
    const value = sigName
      ? sigInput
      : e.target.type === "checkbox"
      ? e.target.checked
      : editedField && editedField.type === CustomFieldTypes.MultiSelect
      ? e.target.value.join("|")
      : e.target.value;
    _.set(newState, name, value);

    setInspectionState(newState);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);

    setFormState(newState);
  };

  const handleFormSave = (saveAction?: string) => {
    const errorFields = getErrorFieldArray(witnessForm.inspectionFormFields);
    const inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    const objectListToSave = convertInspectionStateToValueObject(
      witnessForm.inspectionFormFields,
      inspectionFormValuesToSave
    );
    if (props.incident?.id) {
      createWitnessStatementTrigger({
        id: props.incident.id,
        dto: {
          id: selectedWitness.id,
          inspectionFormFieldValues: objectListToSave,
        },
      }).then((res) => {
        props.updateIncident(res.data);
        if (saveAction === "exit") {
          setToggleAddStatement(false);
        }
        showAlert("success", "Witness statement saved.");
      });
    }
  };

  const getFormButtons = () => {
    return (
      <div className="float-right">
        <Button
          variant="contained"
          className="mr-2"
          onClick={() => handleFormSave()}
        >
          Save
        </Button>
        <Button
          variant="contained"
          className="mr-2"
          onClick={() => handleFormSave("exit")}
        >
          Save and Exit
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setToggleAddStatement(false);
          }}
        >
          Exit Without Saving
        </Button>
      </div>
    );
  };

  const handleStatementClick = (statement: any) => {
    setToggleAddStatement(true);
    setSelectedWitness(statement);
  };

  const handleParticipantsSave = () => {
    const formToSave = _.cloneDeep(formState);
    formToSave.incidentParticipants = [...witnesses, ...affectedParties];
    formToSave.userParticipants = formToSave.userParticipants.map((x) => x.id);
    formToSave.userWitnesses = formToSave.userWitnesses.map((x) => x.id);
    const dto = {
      incidentParticipants: formToSave.incidentParticipants,
      userWitnesses: formToSave.userWitnesses,
      userParticipants: formToSave.userParticipants,
    };
    if (props.incident?.id) {
      updateIncidentParticipantsTrigger({
        id: props.incident.id,
        dto: dto,
      }).then((res) => {
        props.updateIncident(res.data);
        showAlert("success", "Participants saved.");
        resetIsLocked();
      });
    }
  };

  const toggleLock = (name: string) => {
    const newState = { ...isLocked };
    const current = _.get(newState, name);
    _.set(newState, name, !current);
    setIsLocked(newState);
  };

  const resetIsLocked = () => {
    setIsLocked({
      parties: isInitiallyLocked,
      witness: isInitiallyLocked,
    });
  };

  if (toggleAddStatement) {
    return (
      <Container className="form-container mb-5" maxWidth="xl">
        <Form className="card mb-0">
          <Card.Header>
            <Card.Title>{selectedWitness.name} - Witness Statement</Card.Title>
            <div className="ml-auto">{getFormButtons()}</div>
          </Card.Header>
          <Card.Body className="pt-2">
            <Grid.Row>
              {_.orderBy(witnessForm.inspectionFormFields, ["position"]).map(
                (field) => {
                  return (
                    <InspectionFormField
                      field={field}
                      value={inspectionState[field.name]}
                      signatureValue={
                        field.type === CustomFieldTypes.Signature
                          ? inspectionState[field.name + "_signature"]
                          : null
                      }
                      handleInputChange={handleInspectionInputChange}
                      error={inspectionState[`${field.name}Error`]}
                      key={field.name}
                    />
                  );
                }
              )}
            </Grid.Row>
          </Card.Body>
          <Card.Footer>{getFormButtons()}</Card.Footer>
        </Form>
      </Container>
    );
  } else {
    return (
      <>
        {!_.isEmpty(users) && !_.isEmpty(incidentParticipantTypes) && users && (
          <IncidentParticipants
            isLoading={props.isLoading}
            handleSave={handleParticipantsSave}
            handleInputChange={(e: any) => handleInputChange(e)}
            formState={formState}
            witnesses={witnesses}
            setWitnesses={setWitnesses}
            affectedParties={affectedParties}
            setAffectedParties={setAffectedParties}
            users={users}
            incidentParticipantTypes={incidentParticipantTypes}
            isDisabled={() => false}
            isLocked={isLocked}
            toggleLock={toggleLock}
            isInitiallyLocked={isInitiallyLocked}
            hasLock={true}
          />
        )}
        {!_.isEmpty(witnessForm) && (
          <Container className="form-container mb-5" maxWidth="xl">
            <Dimmer active={props.isLoading} loader>
              <Form className="card mb-0">
                <Card.Header>
                  <Card.Title>Witness Statements</Card.Title>
                </Card.Header>
                <Card.Body className="pt-2">
                  <Grid.Row>
                    <Grid.Col width={12}>
                      {_.orderBy(existingWitnessStatements, ["name"]).map(
                        (statement) => {
                          return (
                            <Chip
                              clickable
                              onClick={() => handleStatementClick(statement)}
                              className="mr-2"
                              variant="outlined"
                              icon={<EditIcon />}
                              label={statement.name}
                            />
                          );
                        }
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Form>
            </Dimmer>
          </Container>
        )}
      </>
    );
  }
};

export default IncidentInvestigationParticipants;
