import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Form, Grid } from "components/lynx-components";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "./../../../services/validation";
import "./incident-form.css";

import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { canSubmitInvestigation } from "services/incident-assignment-service";
import { dateUtil } from "../../../services/date-util";
import {
  convertInspectionStateToValueObject,
  getErrorFieldArray,
  getFormFields,
  getFullStateFromInspectionDef,
  getInspectionForm,
  getStateFromExistingInspection,
} from "../../../services/inspection-form-service";
import { CustomFieldTypes, UserRoles } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import { InspectionFormField } from "../inspections/inspection-form-field";
import useAlert from "hooks/useAlert";
import { InspectionFormDto, RootState } from "types";
import IncidentInvestigationTeam from "./incident-investigation-team";
import { IncidentDto } from "types";
import { useUpdateIncidentInspectionMutation } from "services/rtkApi/endpoints/incidents";

interface IncidentInvestigationProps {
  incident: IncidentDto;
  updateIncident: (data: any) => void; // Adjust the type as needed
}

const IncidentInvestigation: React.FC<IncidentInvestigationProps> = (props) => {
  const [inspectionState, setInspectionState] = useState<Record<string, any>>(
    {}
  );
  const [inspectionForm, setInspectionForm] = useState<InspectionFormDto>({
    inspectionFormFields: [],
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const account = useSelector((state: RootState) => state.account);
  const isInitiallyLocked = props.incident?.status?.toLowerCase() === "closed";
  const [isLocked, setIsLocked] = useState({
    investigationTeam: isInitiallyLocked,
    investigationForm: isInitiallyLocked,
  });

  const { showAlert } = useAlert();

  const [updateIncidentInspectionTrigger] =
    useUpdateIncidentInspectionMutation();

  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      if (props.incident.investigationInspectionFormId) {
        loadInvestigationForm(props.incident.investigationInspectionFormId);
      }
    }
  }, [props.incident]);

  const incidentStatus = _.toLower(props.incident.status);

  const loadInvestigationForm = (id: number) => {
    getInspectionForm(id).then((res) => {
      setInspectionForm(res.data);
      // Prepare inspection form
      let fullState = getFullStateFromInspectionDef(
        res.data.inspectionFormFields
      );
      // Merge existing values
      let existingInspectionState = getStateFromExistingInspection(
        props.incident.investigationInspectionFormFieldValues
      );

      fullState = { ...fullState, ...existingInspectionState };
      setInspectionState(fullState);
    });
  };

  const handleInspectionInputChange = (
    e: any,
    sigName?: string,
    sigInput?: any
  ) => {
    const newState = { ...inspectionState };
    const name = sigName ?? e.target.name;
    const editedField = inspectionForm.inspectionFormFields?.find(
      (x) => x.name === name
    );
    const value = sigName
      ? sigInput
      : e.target.type === "checkbox"
      ? e.target.checked
      : editedField && editedField.type === CustomFieldTypes.MultiSelect
      ? e.target.value.join("|")
      : e.target.value;

    _.set(newState, name, value);
    setInspectionState(newState);
  };

  const validateSubmitDataForSave = (): boolean => {
    const newState = { ...inspectionState };
    let isFormValid = false;

    getFormFields(inspectionForm.inspectionFormFields)
      .filter((x: any) => x.isRequired)
      .forEach((field: any) => {
        validationService.validateRequiredField(
          newState,
          field.name,
          `${field.name}Error`,
          field.label
        );
      });

    isFormValid = !validationService.hasError(
      newState,
      ...getErrorFieldArray(inspectionForm.inspectionFormFields)
    );

    if (!isFormValid) {
      setInspectionState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const saveIncident = (saveAction: string) => {
    if (saveAction === "investigationsubmit" && !validateSubmitDataForSave()) {
      return;
    }
    setIsSaving(true);

    const errorFields = getErrorFieldArray(inspectionForm.inspectionFormFields);
    const inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    const objectListToSave = convertInspectionStateToValueObject(
      inspectionForm.inspectionFormFields,
      inspectionFormValuesToSave
    );
    if (props.incident.id) {
      updateIncidentInspectionTrigger({
        id: props.incident.id,
        formFields: objectListToSave,
        saveAction: saveAction,
      })
        .then((res) => {
          props.updateIncident(res.data);
          showAlert(
            "success",
            saveAction === "investigationsubmit"
              ? "Investigation submitted."
              : "Investigation saved."
          );
          if (isInitiallyLocked) {
            resetIsLocked();
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const canSubmit = (): boolean => {
    return canSubmitInvestigation(props.incident, account.id) || false;
  };

  const getButtons = () => {
    return (
      <>
        {canSubmit() && (
          <Button
            className="float-right ml-2"
            variant="contained"
            onClick={() => saveIncident("investigation")}
            disabled={incidentStatus === "closed" && isLocked.investigationForm}
          >
            Save
          </Button>
        )}
        {incidentStatus === "investigation" && canSubmit() && (
          <Button
            className="float-right"
            variant="contained"
            color="success"
            disabled={incidentStatus === "closed"}
            onClick={() => saveIncident("investigationsubmit")}
          >
            Save and Submit
          </Button>
        )}
      </>
    );
  };

  const toggleLock = (name: keyof typeof isLocked) => {
    const newState = { ...isLocked };
    const current = _.get(newState, name);
    _.set(newState, name, !current);
    setIsLocked(newState);
  };

  const resetIsLocked = () => {
    setIsLocked({
      investigationForm: isInitiallyLocked,
      investigationTeam: isInitiallyLocked,
    });
  };

  return (
    <Container className="mt-2" maxWidth="xl">
      <IncidentInvestigationTeam
        incident={props.incident}
        onUpdateInvestigationTeam={(data) => props.updateIncident(data)}
        isLocked={isLocked.investigationTeam}
        toggleLock={(str: any) => toggleLock(str)}
        resetIsLocked={resetIsLocked}
      />
      <Form className="card mb-5">
        <Card.Header>
          <Card.Title>
            <Stack direction="row" alignItems="center">
              <span>Incident Investigation Form</span>
              {roleMatch([UserRoles.Admin]) && isInitiallyLocked && (
                <IconButton
                  aria-label="Lock"
                  onClick={() => toggleLock("investigationForm")}
                  title={isLocked.investigationForm ? "Unlock" : "Lock"}
                >
                  {isLocked.investigationForm ? (
                    <Lock />
                  ) : (
                    <LockOpenOutlined color="success" />
                  )}
                </IconButton>
              )}
            </Stack>
          </Card.Title>
          <div className="ml-auto">{getButtons()}</div>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            {!_.isEmpty(inspectionState) &&
              _.orderBy(inspectionForm.inspectionFormFields, ["position"]).map(
                (field) => (
                  <InspectionFormField
                    field={field}
                    value={inspectionState[field.name as string]}
                    signatureValue={
                      field.type === CustomFieldTypes.Signature
                        ? inspectionState[field.name + "_signature"]
                        : null
                    }
                    handleInputChange={handleInspectionInputChange}
                    error={inspectionState[`${field.name}Error`]}
                    key={field.name}
                    disabled={isLocked.investigationForm || !canSubmit}
                  />
                )
              )}
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          {getButtons()}
          {props.incident.investigationSubmittedDateTimeUtc && (
            <div>
              <Typography component="span">
                Investigation submitted by:{" "}
                {props.incident.investigationSubmittedByUserFullName}{" "}
                {dateUtil.convertDateTimeToLocal(
                  props.incident.investigationSubmittedDateTimeUtc
                )}
              </Typography>
            </div>
          )}
        </Card.Footer>
      </Form>
      {isSaving && (
        <LynxDialog
          open={isSaving}
          title="Saving investigation. Do not close the window."
          description={
            <div className="d-flex align-items-center justify-content-center mt-4">
              <CircularProgress />
            </div>
          }
        />
      )}
    </Container>
  );
};

export default IncidentInvestigation;
